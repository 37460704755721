import React, {
    forwardRef,
    HTMLAttributes,
    ReactNode, RefObject,
    useEffect, useLayoutEffect,
    useRef,
    useState,
} from 'react';

import cl from './style.module.css';
import useResize from 'shared/hooks/useResize';
import useDebounce from 'shared/hooks/useDebounce';

export interface SlideBlockProps extends HTMLAttributes<HTMLDivElement> {
    titleContent: ReactNode;
    angleBracketColor?: string;
    duration?: number;
    marginBottomTitle?: number;
    elemRefHeightTrigger?: RefObject<HTMLElement>;
    defaultOpen?: boolean;
}

const SlideBlock = forwardRef<HTMLDivElement, SlideBlockProps>(({
    className,
    titleContent,
    children,
    elemRefHeightTrigger,
    duration = 200,
    marginBottomTitle = 18,
    angleBracketColor = 'gray',
    defaultOpen = false,
    style,
}, ref) => {
    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
    const [contentHeight, setContentHeight] = useState<number>(0);
    const contentRef = useRef<HTMLDivElement>(null);

    const debouncedOpen = useDebounce(isOpen, 300);

    useResize(elemRefHeightTrigger, () =>
        elemRefHeightTrigger?.current && setContentHeight(elemRefHeightTrigger.current.getBoundingClientRect().height));

    useLayoutEffect(() => {
        if(
            contentRef?.current?.style &&
            !!contentRef.current?.getBoundingClientRect().height
        ) {
            setContentHeight(contentRef.current?.getBoundingClientRect().height);
            contentRef.current.style.height = '0';
        }
    }, []);

    useEffect(() => {
        if(contentRef?.current) {
            contentRef.current.style.height = isOpen ? `${contentHeight}px` : '0';
            if(isOpen) {
                contentRef.current.style.display = 'block';
            }
        }
    }, [isOpen, contentHeight]);

    useEffect(() => {
        if(contentRef?.current && !debouncedOpen) {
            contentRef.current.style.display = 'none';
        }
    }, [debouncedOpen]);

    return (
        <div className={className} ref={ref} style={style}>
            <div
                className={cl.title}
                onClick={() => setIsOpen(!isOpen)}
                onKeyDown={e => e.key === 'Enter' && setIsOpen(!isOpen)}
                role="button"
                tabIndex={0}
                aria-expanded={isOpen}
                style={{
                    marginBottom: isOpen ? `${marginBottomTitle}px` : 0,
                    transition: `all ${duration}ms ease-in-out`,
                }}
            >
                {titleContent}
                <div
                    className={
                        isOpen
                            ? [cl.angleBracket, cl.angleBracketVisible].join(
                                  ' ',
                              )
                            : cl.angleBracket
                    }
                    style={{
                        borderColor: `var(--color-${angleBracketColor})`,
                        transition: `all ${duration}ms ease-in-out`,
                    }}
                />
            </div>
            <div
                className={cl.content}
                style={{ transition: `height ${duration}ms ease-in-out` }}
                ref={contentRef}
            >
                {children}
            </div>
        </div>
    );
});

export default SlideBlock;
