import React, { useEffect } from 'react';
import 'shared/style/constants.css';
import 'shared/style/globals.css';
import 'shared/fonts/fonts.css';
import 'shared/style/antd.css';
import Report from 'pages/report';
import Contacts from 'pages/contacts';
import Reference from 'pages/reference';
import Login from 'pages/login';
import Main from 'pages/main';
import PolicyGuide from 'pages/policy-guide';
import PolicyReport from 'pages/policy-report';
import Header from 'widgets/Header';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { setIsAuth } from 'shared/store/reducers/commonSlice';
import { HelmetProvider } from 'react-helmet-async';

function App() {
    const dispatch = useAppDispatch();
    const { isAuth } = useAppSelector(state => state.common);

    useEffect(() => {
        if(localStorage.getItem('isAuth') === 'true') {
            dispatch(setIsAuth(true));
        } else {
            dispatch(setIsAuth(false));
        }
    }, [localStorage.getItem('isAuth')]);

    return (
        <div className="App">
            <BrowserRouter>
                <HelmetProvider>
                    <Header/>
                    <Routes>
                        {isAuth
                            ? <>
                                <Route path="/" element={<Main />} />
                                <Route path="/policy-guide/:reportNumber" element={<PolicyReport />} />
                                <Route path="/policy-guide" element={<PolicyGuide />} />
                                <Route path="/report/:reportNumber" element={<Report />} />
                                <Route path="/contacts" element={<Contacts />} />
                                <Route path="/reference" element={<Reference />} />
                                <Route path="*" element={<Navigate to="/" replace/>}/>
                              </>
                            : <>
                                <Route path="/login" element={<Login />} />
                                <Route path="*" element={<Navigate to="/login" replace/>}/>
                              </>
                        }
                    </Routes>
                </HelmetProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
