import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";
import { IError } from "shared/types/error";
import { AnyType } from "myzod";
import { Article } from 'entities/filter/types';



type ArticlesResponse = Article[];


const API_URL = 'https://salaryguide.ru/api';

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    credentials: 'include',
});

const toastBaseQuery: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {
        loading?: string | undefined,
        success?: string | undefined,
        error?: string | undefined,
        schema?: AnyType
    }
> = async (args, api, extraOptions) => {
    let t;
    if (extraOptions?.loading)
        t = toast.loading(extraOptions.loading);

    if (api.type === 'mutation') {
        await baseQuery({ url: '/get_csrf/' }, api, extraOptions);
    }
    let res = await baseQuery(args, api, extraOptions);

    /* if (api.type === 'query' && !extraOptions?.schema) {
        throw new Error(
            'Для GET запроса необходимо добавить schema для валидации данных',
        );
    } */

    if(res?.error?.status === 401 && typeof args !== 'string' && args.url !== '/user/login/') {
        await baseQuery({ url: '/user/logout/' }, api, extraOptions);
        localStorage.removeItem('isAuth');
    }

    if (res.error) {
        const error: IError = res.error as IError;
        toast.error(error?.data?.detail || extraOptions?.error || 'Ошибка при загрузке данных', {id: t});
    } else {
        if (extraOptions?.success)
            t = toast.success(extraOptions.success, {id: t});
    }

    if (extraOptions && extraOptions.schema) {
        const parsedData = extraOptions.schema.parse(res.data);

        return { ...res, data: parsedData };
    }

    return res;
}

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: toastBaseQuery,
    tagTypes: [
        'Sections',
        'Reports',
        'SettingsSalary',
        'Filters',
        'SubReport',
        'Templates',
        'Template',
        'PolicyReports',
        'PolicyReport',
        'PolicyReportData',
    ],
    endpoints: (builder) => ({
        getArticles: builder.query<ArticlesResponse, void>({
            query: () => ({
                url: 'sections/',
                method: 'GET',
            }),
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: 'Sections' as const, id })), { type: 'Sections', id: 'LIST' }]
                    : [{ type: 'Sections', id: 'LIST' }],
            extraOptions: {
                loading: 'Загрузка разделов...',
                error: 'Ошибка при загрузке разделов',
            }
        }),
        // Можно добавить дополнительные endpoints
    }),
});

export const {
    util: { resetApiState },
} = apiSlice;


export const { useGetArticlesQuery } = apiSlice;
