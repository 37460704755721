import React, { FC } from 'react';
import cl from './style.module.css';
import Text from 'shared/ui/Text';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { useLogoutMutation } from 'entities/user/model/enpoints';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { setIsAuth } from 'shared/store/reducers/commonSlice';
import { resetApiState } from 'shared/store/api';
import { useScreenWidth } from 'shared/hooks/useScreenWidth';
import { LogoutOutlined } from '@ant-design/icons';

const Header: FC = () => {
    const { pathname } = useLocation();
    const width = useScreenWidth();
    const dispatch = useAppDispatch();
    const { isAuth } = useAppSelector(state => state.common);
    const [logout, { isLoading: isLoadingLogout }] = useLogoutMutation();

    const handleLogout = () => {
        if(!isLoadingLogout) {
            logout().unwrap().then(() => {
                dispatch(setIsAuth(false));
                dispatch(resetApiState());
            })
        }
    };

    return (
        <header className={cl.header}>
            <Link to="/" className={cl.leftHeader}>
                <Text variant="m" color="white">SalaryGuide</Text>
                <Text variant="m" color="gray-5">Online</Text>
            </Link>
            {isAuth &&
                <div className={cl.rightHeader}>
                    {width > 480 &&
                        <>
                            <Link to="/policy-guide">
                                <Text
                                    variant={width > 480 ? 's' : 'xs'}
                                    color="gray-3"
                                    semiBold={pathname.startsWith('/policy-guide')}
                                >
                                    PolicyGuide
                                </Text>
                            </Link>
                            <Link to="/reference">
                                <Text
                                    variant={width > 480 ? 's' : 'xs'}
                                    color="gray-3"
                                    semiBold={pathname.startsWith('/reference')}
                                >
                                    Справка
                                </Text>
                            </Link>
                            <Link to="/contacts">
                                <Text
                                    variant={width > 480 ? 's' : 'xs'}
                                    color="gray-3"
                                    semiBold={pathname.startsWith('/contacts')}
                                >
                                    Контакты
                                </Text>
                            </Link>
                        </>
                    }
                    <div
                        className={cl.exit}
                        role="button"
                        tabIndex={0}
                        onClick={handleLogout}
                        onKeyDown={e => e.key === 'Enter' && handleLogout()}
                    >
                        <Text variant={width > 480 ? 's' : 'xs'} color="gray-3">Выйти</Text>
                        <LogoutOutlined />
                    </div>
                </div>
            }
        </header>
    );
};

export default Header;