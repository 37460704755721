import { array, Infer, number, object, string, union, literal, boolean } from 'myzod';

const citiesData = object({
    id: number(),
    regional_rate: number(),
});

const currencyData = object({
    id: number(),
    type: string(),
    rate: number(),
    round: number(),
});

// Создаём тип, который может быть currencyData или null
const nullableCurrencyData = union([currencyData, literal(null)]);

export const settingsSchema = object({
    cities_rates: array(citiesData),
    currency_types: object({
        raw_currency_type: nullableCurrencyData, // Теперь может быть null
        all_currency_types: array(currencyData),
    }),
    tax_rates: array(object({
        tax_rate: number(),
    })),
    index_rates: array(object({
        index_rate: number(),
    })),
    current_date: string(),
    settings: object({
        id: number().nullable().optional(),
        indexed: boolean().nullable().optional(),
        absolute_indexation: boolean().nullable().optional(),
        annual_indexation: boolean().nullable().optional(),
        months_since_review: number().nullable().optional(),
        data_collection_period: string().nullable().optional(),
        tax_included_gross: boolean().nullable().optional(),
        regional_coefficient_enabled: boolean().nullable().optional(),
        report: number().nullable().optional()
    }).nullable()
});

export type SettingsResponse = Infer<typeof settingsSchema>;

export type CurrencyI = Infer<typeof currencyData>;
