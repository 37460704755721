import React, { FC, useEffect, useState } from 'react';
import cl from './style.module.css';
import { Checkbox, Collapse } from 'antd';
import Text from 'shared/ui/Text';
import { setCities } from 'shared/store/reducers/commonSlice';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { ISubjectOption } from '../../types';

interface RegionProps {
    idx: number;
    letter: string;
    region: ISubjectOption;
    regions?:  ISubjectOption[];
    clickedActiveKey?: string | null;
    setClickedActiveKey: (key: string | null) => void;
    activeKeys?: string[];
    setActiveKeys: (key: string[] | undefined) => void;
}

const Region: FC<RegionProps> = ({
    idx,
    letter,
    region,
    regions,
    clickedActiveKey,
    setClickedActiveKey,
    activeKeys,
    setActiveKeys
}) => {
    const dispatch = useAppDispatch();
    const { cities } = useAppSelector(state => state.common);
    const [notAllSelected, setNotAllSelected] = useState<boolean>(false);
    const [allSelected, setAllSelected] = useState<boolean>(false);

   

    useEffect(() => {
        setAllSelected(region.cities.every(c =>
            cities?.split(',').some(city => city === c.id.toString())));
        setNotAllSelected(!!cities && region.cities.some(c =>
            cities.split(',').some(city => city === c.id.toString())))
    }, [cities]);

    return (
        <div className={`${cl.customCollapsePanel} ${clickedActiveKey === idx.toString() ? cl.myElement : ''}`}>
        <Collapse
            expandIconPosition="start"
            defaultActiveKey={Array.from({length: regions?.length ?? 0}, (_, i) => i.toString())}
            ghost
        >
            <Collapse.Panel
                header={
                    <div className={cl.checkbox}>
                        <Checkbox
                            indeterminate={!allSelected && notAllSelected}
                            checked={allSelected}
                            onClick={e => {
                                setClickedActiveKey(idx.toString());
                                e.stopPropagation();
                            }}
                            onChange={e =>
                                dispatch(setCities(e.target.checked
                                    ? cities
                                        ? `${cities},${region.cities
                                                .filter(c => !cities.includes(c.id.toString()))
                                                .map(c => c.id)
                                                .join(',')}`
                                        : region.cities.map(c => c.id).join(',')
                                    : cities?.split(',').filter(city =>
                                        !region.cities.some(c => c.id.toString() === city)).join(',') ?? '',
                                ))}
                        />
                        <Text className={cl.region} variant="xxs" color="gray-8">
                            {region.subject_name}
                        </Text>
                    </div>
                }
                key={idx}
            >
                <div className={[cl.line, cl.cities].join(' ')}>
                    {region.cities.map(c =>
                        <Checkbox
                            key={c.id}
                            checked={cities?.split(',').some(city => city === c.id.toString())}
                            onClick={() => {
                                setClickedActiveKey(idx.toString());
                            }}
                            onChange={e => dispatch(setCities(e.target.checked
                                ?   cities ? `${cities},${c.id}` : `${c.id}`
                                :   cities?.split(',')
                                        .filter(city => city !== c.id.toString())
                                        .join(',') ?? ''
                            ))}
                        >
                            <Text variant="xxs" color="gray-8">{c.name}</Text>
                        </Checkbox>
                    )}
                </div>
            </Collapse.Panel>
        </Collapse>
        </div>
    );
};

export default Region;
