export const JsonContentExample = [
    {
        "id": 2,
        "section_number": "1.",
        "section_name": "Политики оплаты труда",
        "subsections": [
            {
                "id": 3,
                "subsection_name": "1.1 Постоянные выплаты",
                "elements": [
                    {
                        "id": 12,
                        "text": {
                            "id": 2,
                            "text": "Lorem Ipsum - это текст-\"рыба\", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной \"рыбой\" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.",
                            "reportelement": 12
                        },
                        "diagram": null,
                        "table": null,
                        "element_id": "1.1.1",
                        "element_code": "C",
                        "subsection": 3
                    },
                    {
                        "id": 13,
                        "text": null,
                        "diagram": {
                            "id": 3,
                            "values": [
                                {
                                    "id": 4,
                                    "description": "командирование расходы",
                                    "value": 82.1,
                                    "diagram": 3
                                },
                                {
                                    "id": 5,
                                    "description": "расходы",
                                    "value": 80.1,
                                    "diagram": 3
                                }
                            ],
                            "title": "Диаграмма. Льготы сотрудников региональной сети, все компании, %",
                            "reportelement": 13
                        },
                        "table": null,
                        "element_id": "1.1.2",
                        "element_code": "VD",
                        "subsection": 3
                    },
                ]
            },
            {
                "id": 4,
                "subsection_name": "1.4 Разовые выплаты",
                "elements": [
                    {
                        "id": 8,
                        "text": {
                            "id": 1,
                            "text": "Lorem Ipsum - это текст-\"рыба\", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной \"рыбой\" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.",
                            "reportelement": 8
                        },
                        "diagram": null,
                        "table": null,
                        "element_id": "1.4.1",
                        "element_code": "C",
                        "subsection": 4
                    },
                    {
                        "id": 10,
                        "text": null,
                        "diagram": {
                            "id": 2,
                            "values": [
                                {
                                    "id": 1,
                                    "description": "индивидуалние оклады",
                                    "value": 59.2,
                                    "diagram": 2
                                },
                                {
                                    "id": 2,
                                    "description": "тарифная сетка",
                                    "value": 50.1,
                                    "diagram": 2
                                },
                                {
                                    "id": 3,
                                    "description": "тарифная сетка 2",
                                    "value": 60.1,
                                    "diagram": 2
                                }
                            ],
                            "title": "Диаграмма. Какая система окладов используется в компании",
                            "reportelement": 10
                        },
                        "table": null,
                        "element_id": "1.4.6",
                        "element_code": "D",
                        "subsection": 4
                    },
                    {
                        "id": 11,
                        "text": null,
                        "diagram": null,
                        "table": {
                            "id": 1,
                            "values": [
                                {
                                    "id": 1,
                                    "subTitle": "Используется сейчас",
                                    "data": {
                                        "rows": [
                                            {
                                                "Оформление виз и загранпаспортов": [
                                                    23.3,
                                                    24,
                                                    20,
                                                    24,
                                                    20
                                                ]
                                            },
                                            {
                                                "Заказ и приобретение железнодорожных и авиабилетов": [
                                                    23.3,
                                                    12,
                                                    80,
                                                    24,
                                                    20
                                                ]
                                            },
                                            {
                                                "Заказ и приобретение туристических путевок": [
                                                    6.7,
                                                    8,
                                                    0,
                                                    4,
                                                    20
                                                ]
                                            },
                                            {
                                                "Приобретение билетов на развлекательные мероприятия": [
                                                    20,
                                                    24,
                                                    0,
                                                    16,
                                                    40
                                                ]
                                            },
                                            {
                                                "Подписка на газеты и журналы": [
                                                    23.3,
                                                    28,
                                                    0,
                                                    20,
                                                    40
                                                ]
                                            },
                                            {
                                                "Бытовые услуги": [
                                                    23.3,
                                                    28,
                                                    0,
                                                    28,
                                                    0
                                                ]
                                            },
                                            {
                                                "Парикмахерские и косметологические услуги": [
                                                    26.7,
                                                    28,
                                                    20,
                                                    32,
                                                    0
                                                ]
                                            }
                                        ],
                                        "columns": [
                                            "Все компании",
                                            "Российские",
                                            "Иностранные",
                                            "Москва",
                                            "Регион"
                                        ]
                                    },
                                    "table": 1
                                },
                                {
                                    "id": 2,
                                    "subTitle": "Планируется внедрить",
                                    "data": {
                                        "rows": [
                                            {
                                                "Оформление виз и загранпаспортов": [
                                                    23.3,
                                                    24,
                                                    20,
                                                    24,
                                                    20
                                                ]
                                            },
                                            {
                                                "Заказ и приобретение железнодорожных и авиабилетов": [
                                                    23.3,
                                                    12,
                                                    80,
                                                    24,
                                                    20
                                                ]
                                            },
                                            {
                                                "Заказ и приобретение туристических путевок": [
                                                    6.7,
                                                    8,
                                                    0,
                                                    4,
                                                    20
                                                ]
                                            },
                                            {
                                                "Приобретение билетов на развлекательные мероприятия": [
                                                    20,
                                                    24,
                                                    0,
                                                    16,
                                                    40
                                                ]
                                            },
                                            {
                                                "Подписка на газеты и журналы": [
                                                    23.3,
                                                    28,
                                                    0,
                                                    20,
                                                    40
                                                ]
                                            },
                                            {
                                                "Бытовые услуги": [
                                                    23.3,
                                                    28,
                                                    0,
                                                    28,
                                                    0
                                                ]
                                            },
                                            {
                                                "Парикмахерские и косметологические услуги": [
                                                    26.7,
                                                    28,
                                                    20,
                                                    32,
                                                    0
                                                ]
                                            }
                                        ],
                                        "columns": [
                                            "Все компании",
                                            "Российские",
                                            "Иностранные",
                                            "Москва",
                                            "Регион"
                                        ]
                                    },
                                    "table": 1
                                }
                            ],
                            "title": "Таблица. Какая система окладов используется в компании",
                            "reportelement": 11
                        },
                        "element_id": "1.4.7",
                        "element_code": "T",
                        "subsection": 4
                    }
                ]
            }
        ]
    }
];

export const SectionTypeEnum = {
    STRING: 'C',                  // Строка текстовая
    DIAGRAM_DATA: 'D',            // Диаграмма
    TABLE: 'T',                   // Таблица
    DIAGRAM_VERTICAL_DATA: 'VD',  // Вертикальная диаграмма
} as const;
