/* globals ValueOf */
import React, { FC, HTMLAttributes, useCallback, useEffect, useMemo, useRef } from 'react';
import cl from './style.module.css';
import cn from 'clsx';
import { Button, Table } from 'antd';
import { SectionTypeEnum } from 'entities/policy/constants';
import { useGetPolicyReportDataQuery, useGetPolicyReportQuery } from 'entities/policy/model/endpoints';
import Text from 'shared/ui/Text';
import { exhaustiveCheck } from 'shared/utils';
import Chart from 'shared/ui/Chart';
import Spinner from 'shared/ui/Spinner';
import { useScreenWidth } from 'shared/hooks/useScreenWidth';
import { useParams } from 'react-router-dom';
import type { SectionsI } from 'entities/policy/types';
import type { ElementI } from 'entities/policy/model/schemas';

interface ContentProps extends HTMLAttributes<HTMLDivElement> {
    isOpenTitles: boolean;
    sections: SectionsI;
    setSections: (value: SectionsI) => void;
}

const Content: FC<ContentProps> = ({ isOpenTitles, sections, setSections }) => {
    const { reportNumber } = useParams();
    const width = useScreenWidth();
    const sectionRef = useRef<HTMLDivElement>(null);
    const { data: report } = useGetPolicyReportQuery(reportNumber ?? '');
    const { data: reportData, isLoading: isLoadingReportData } = useGetPolicyReportDataQuery(reportNumber ?? '');

    useEffect(() => {
        if(report?.sections.length && (!sections.section && !sections.subSection || !sections.section && sections.subSection)) {
            setSections({
                section: report?.sections[0].id,
                subSection: report?.sections[0].subsections?.length ? report?.sections[0].subsections[0].id : null
            });
        } else if(report?.sections?.length && sections.section && !sections.subSection) {
            const subsection = report?.sections.find(s => s.id === sections.section)?.subsections;
            setSections({
                section: sections.section,
                subSection: subsection?.length
                    ? subsection[0].id
                    : null
            });
        }
    }, [report]);

    const getContent = useCallback((elementType: ValueOf<typeof SectionTypeEnum>, element: ElementI) => {
        switch (elementType) {
            case SectionTypeEnum.STRING:
                return <Text variant="small" color="primary">{element.text?.text}</Text>;
            case SectionTypeEnum.TABLE:
                return (
                    <div className={cl.tablesWrapper}>
                        <Text variant="small" color="primary" medium>{element.table?.title}</Text>
                        {element.table?.values.map(t =>
                            <div key={t.id} className={cl.tableWrapper}>
                                <Text variant="s" color="primary" medium>{t.subTitle}</Text>
                                <Table
                                    className={cl.table}
                                    columns={[{
                                            title: '',
                                            key: 'name',
                                            dataIndex: 'name'
                                        }, ...t.data?.columns?.map(c => ({
                                            title: c,
                                            key: c,
                                            dataIndex: c,
                                            render: (elem: string) => <Text variant="s" color="primary">{elem}</Text>
                                        }))
                                    ]}
                                    dataSource={t.data.rows.map(r => Object.entries(r).map(([key, value]) => {
                                            const elems = value?.map((val, i) => ({
                                                [t.data.columns[i]]: val
                                            }));
                                            const finalObj = elems?.reduce((obj, curValue) => Object.assign(obj, curValue), {});
                                            return {
                                                key,
                                                name: key,
                                                ...finalObj
                                            };
                                        })
                                        .reduce((obj, curValue) => Object.assign(obj, curValue), {})
                                    )}
                                    pagination={false}
                                />
                            </div>
                        )}
                    </div>
                );
            case SectionTypeEnum.DIAGRAM_DATA:
            case SectionTypeEnum.DIAGRAM_VERTICAL_DATA:
                return (
                    <Chart
                        option={{
                            title: {
                                text: element.diagram?.title ?? undefined,
                                textStyle: {
                                    fontFamily: 'Inter, sans-serif'
                                }
                            },
                            legend: {
                                top: 35,
                                padding: 15
                            },
                            tooltip: {
                                axisPointer: {
                                    // Use axis to trigger tooltip
                                    type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
                                }
                            },
                            grid: {
                                top: width > 480 ? '30%' : '45%',
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true,
                            },
                            xAxis: {
                                type: elementType === SectionTypeEnum.DIAGRAM_VERTICAL_DATA ? 'category' : 'value',
                                data: elementType === SectionTypeEnum.DIAGRAM_VERTICAL_DATA
                                    ? element.diagram?.values.map(v => v.description ?? '')
                                    : undefined,
                                axisLabel: {
                                    fontFamily: 'Inter, sans-serif'
                                }
                            },
                            yAxis: {
                                type: elementType === SectionTypeEnum.DIAGRAM_VERTICAL_DATA ? 'value' : 'category',
                                data: elementType === SectionTypeEnum.DIAGRAM_VERTICAL_DATA
                                    ? undefined
                                    : element.diagram?.values.map(v => v.description ?? ''),
                                axisLabel: {
                                    fontFamily: 'Inter, sans-serif'
                                },
                            },
                            series: element.diagram?.values.map((v, idx) => ({
                                name: v.description ?? undefined,
                                data: Array.from(Array(element.diagram?.values.length)).map((_, i) => idx === i ? v.value : 0),
                                stack: 'stack',
                                type: 'bar',
                                label: {
                                    fontFamily: 'Inter, sans-serif'
                                }
                            })),
                        }}
                        resizeRef={sectionRef}
                    />
                );
            default:
                exhaustiveCheck(elementType);
                return '';
        }
    }, [reportData]);

    const section = useMemo(() => reportData?.find(s => s.id === sections.section), [reportData, sections.section]);

    const subSection = useMemo(() => section?.subsections?.find(ss => ss.id === sections.subSection), [section, sections.subSection]);

    const goToPrevSubSection = () => {
        if(section && subSection) {
            const subSectionIdx = section.subsections.findIndex(ss => ss.id === subSection.id);
            if(subSectionIdx > 0) {
                setSections({
                    section: section.id,
                    subSection: section.subsections[subSectionIdx - 1].id
                })
            } else if(subSectionIdx === 0 && reportData) {
                const sectionIdx = reportData.findIndex(rd => rd.id === section.id);
                if(sectionIdx > 0) {
                    setSections({
                        section: reportData[sectionIdx - 1].id,
                        subSection: reportData[sectionIdx - 1].subsections.length
                            ? reportData[sectionIdx - 1]
                                .subsections[reportData[sectionIdx - 1].subsections.length - 1].id
                            : null
                    });
                } else
                if(sectionIdx === 0 && reportData.length) {
                    setSections({
                        section: reportData[reportData.length - 1].id,
                        subSection: reportData[reportData.length - 1].subsections.length
                            ? reportData[reportData.length - 1]
                                .subsections[reportData[reportData.length - 1].subsections.length - 1].id
                            : null
                    });
                }
            }
        }
    };

    const goToNextSubSection = () => {
        if(section && subSection) {
            const subSectionIdx = section.subsections.findIndex(ss => ss.id === subSection.id);
            if(subSectionIdx >= 0 && subSectionIdx < section.subsections.length - 1) {
                setSections({
                    section: section.id,
                    subSection: section.subsections[subSectionIdx + 1].id
                })
            } else if(subSectionIdx >= 0 && subSectionIdx === section.subsections.length - 1 && reportData) {
                const sectionIdx = reportData.findIndex(rd => rd.id === section.id);
                if(sectionIdx >= 0 && sectionIdx < reportData.length - 1) {
                    setSections({
                        section: reportData[sectionIdx + 1].id,
                        subSection: reportData[sectionIdx + 1].subsections.length
                            ? reportData[sectionIdx + 1].subsections[0].id
                            : null
                    });
                } else
                if(sectionIdx >= 0 && sectionIdx === reportData.length - 1 && reportData.length) {
                    setSections({
                        section: reportData[0].id,
                        subSection: reportData[0].subsections.length
                            ? reportData[0].subsections[0].id
                            : null
                    });
                }
            }
        }
    };

    return (
        <div className={cn(cl.wrapper, { [cl.wrapperFull]: !isOpenTitles })} ref={sectionRef}>
            {isLoadingReportData && !reportData && <Spinner size="l" />}
            {!isLoadingReportData && !!reportData &&
                <div className={cl.section}>
                    <Text variant="s" color="gray-7" as="h2" semiBold>
                        {section?.section_number} {section?.section_name}
                    </Text>
                    <div className={cl.subsection}>
                        <Text variant="header" color="brand-1" medium>{subSection?.subsection_name}</Text>
                        {subSection?.elements?.map(element =>
                            <div key={element.id} className={cl.element}>
                                {getContent(element.element_code as ValueOf<typeof SectionTypeEnum>, element)}
                            </div>
                        )}
                    </div>
                    <div className={cl.buttonsNavigation}>
                        <Button onClick={goToPrevSubSection}>Назад</Button>
                        <Button onClick={goToNextSubSection}>Вперед</Button>
                    </div>
                </div>
            }
        </div>
    );
};

export default Content;
