import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import cl from './style.module.css';
import Text from '../Text';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { useGetSettingsQuery } from 'entities/settings/model/endpoints';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import {
    setColumnHeadersInTable, setCountMonths,
    setDisplayObject, setIncludeTax,
    setIndex, setIsApplyDistrictCoefficient,
    setPercentsInTable,
} from 'shared/store/reducers/commonSlice';
import cn from 'clsx';
import { isNil } from 'lodash';

interface TableProps {
    title: string;
    numberObj: {
        percent: string;
        columnHeader: string;
        number: number | null;
    }[][];
    percents: string[];
    columnHeaders: string[];
    districtCoefficient: number;
    // countMonths: number;
    viewMode: 'standard' | 'transposed';
}

const Table: FC<TableProps> = ({
                                   title,
                                   viewMode,
                                   numberObj,
                                   percents,
                                   columnHeaders,
                                   districtCoefficient,
                                   // countMonths
                               }) => {
    const dispatch = useAppDispatch();
    const { reportNumber } = useParams();
    const {
        isApplyDistrictCoefficient,
        currency,
        includeTax,
        index,
        isShowMedian,
        isShowAverage,
        isShowCompanyTable,
        isShowEmployeeTable,
        displayObject,
        countMonths,
    } = useAppSelector(state => state.common);
    const [hoverIdx, setHoverIdx] = useState<number | null>(null);
    const { data: settings } = useGetSettingsQuery(reportNumber ?? '0');
    const [isTransposed, setIsTransposed] = useState(false);
    const [showAll, setShowAll] = useState(false);

    const toggleTranspose = () => {
        setIsTransposed(!isTransposed);
    };

    const toggleShowAll = () => {
        setShowAll(!showAll);
    };

    const orderedPercents = ['Среднее', '10%', '25%', '50%', '75%', '90%', '% от Доход в год'];
    const comcount = ['Кол-во компаний', 'Подчинённых'];
    const offset = columnHeaders.length;

    useEffect(() => {
        if(!displayObject.length) {
            dispatch(setColumnHeadersInTable(columnHeaders));
            if(title.toLowerCase() !== 'компания' && title.toLowerCase() !== 'компании') {
                dispatch(setPercentsInTable([...percents, 'Кол-во компаний']));
                dispatch(setDisplayObject({
                    percents: [...percents, 'Кол-во компаний'],
                    columnHeaders
                }));
            }
        }
    }, []);

    const checkSubordinateEmployees = (i: number) =>
        (i === percents.length - 1 && title.toLowerCase() !== 'сотрудник'
            && title.toLowerCase() !== 'сотрудники' || i !== percents.length - 1);



    const filteredPercents = percents.filter(p =>
        p === 'Кол-во компаний' || p === 'Подчинённых сотр.'
    );


    const checkVisibilityOfRow = (value: string) => {
        switch (value.toLowerCase()) {
            case 'среднее':
                return isShowAverage;
            case 'медиана':
                return isShowMedian;
            default:
                return displayObject.some(d => d.key === value && d.value);
        }
    };

    const { currencyCoefficient, round } = useMemo(() => {
        const currentCurrency = settings?.currency_types.all_currency_types.find(c => c.type === currency);
        return ({
            currencyCoefficient: currentCurrency?.rate || 1,
            round: currentCurrency?.round || 1,
        });
    }, [currency, settings]);

    const getNumberWithCoefficient = useCallback((ch: string, percent: string, flagCoefficient: boolean) => {
        let number = numberObj.find(no => no.length && no[0].percent === percent)
            ?.find(no => (no?.columnHeader ?? '') === ch)?.number;

        if (number != null && flagCoefficient) {
            if (isApplyDistrictCoefficient) {
                number *= districtCoefficient;
                if (isNaN(number)) return ''; // Проверка на NaN после каждого шага
            }
            if (includeTax && settings?.tax_rates[0]?.tax_rate) {
                const taxRate = settings.tax_rates[0].tax_rate;
                number = (100 - taxRate) * number / 100;
                if (isNaN(number)) return ''; // Проверка на NaN после каждого шага
            }
            if (index && settings?.index_rates[0]?.index_rate) {
                switch (index) {
                    case 'year':
                        number *= 1 + settings.index_rates[0].index_rate / 100;
                        break;
                    case 'abs':
                        number *= 1 + (countMonths / 12) * settings.index_rates[0].index_rate / 100;
                        break;
                    // Остальные случаи...
                }
                if (isNaN(number)) return ''; // Проверка на NaN после каждого шага
            }
            number = Math.round((number / currencyCoefficient) * 100);
            if (isNaN(number)) return ''; // Проверка на NaN после каждого шага
        }

        // Используйте локаль 'en-US' для форматирования числа без запятых
        return number ? number.toLocaleString('en-US').replace(/,/g, ' ') : ' ';
    }, [isApplyDistrictCoefficient, districtCoefficient, currencyCoefficient, includeTax, index, round, countMonths, settings]);


    if((title.toLowerCase() === 'компания' || title.toLowerCase() === 'компании') && !isShowCompanyTable ||
        (title.toLowerCase() === 'сотрудник' || title.toLowerCase() === 'сотрудники') && !isShowEmployeeTable) {
        return null;
    }



    // Renders the table data in the standard view
    const renderStandardData = () => {
        // Render the table rows and columns based on the standard view
        return (
            <div className={cl.wrapper}>


                <div className={cl.mainColumn}>
                    <Text className={cl.paddingLeft} variant="xxs" color="gray-9">
                        {title.replace('Сотрудник', 'СОТРУДНИКИ').replace('Компания', 'КОМПАНИИ')}
                    </Text>
                    <div className={cl.separator} />
                    {percents.map((p, i) =>
                        i !== 6
                            ?   checkSubordinateEmployees(i) && checkVisibilityOfRow(p) &&
                            <Text
                                key={p}
                                className={cn(cl.percent, cl.paddingLeft, {
                                    [cl.hoverElem]: hoverIdx === i
                                })}
                                variant="xxs"
                                color="gray-9"
                                onMouseEnter={() => setHoverIdx(i)}
                                onMouseLeave={() => setHoverIdx(null)}
                            >
                                {p.replace('сотр.', '')}
                            </Text>
                            :   checkSubordinateEmployees(i) && checkVisibilityOfRow(p) &&
                            <React.Fragment key={p}>
                                <div className={cl.separator} />
                                <Text
                                    className={cn(cl.percent, cl.paddingLeft, {
                                        [cl.hoverElem]: hoverIdx === i
                                    })}
                                    variant="xxs"
                                    color="gray-9"
                                    onMouseEnter={() => setHoverIdx(i)}
                                    onMouseLeave={() => setHoverIdx(null)}
                                >
                                    {p.replace('сотр.', '')}
                                </Text>
                            </React.Fragment>
                    )}
                </div>
                <div className={cl.otherTable}>
                    {columnHeaders.map(ch =>
                        <div key={ch} className={cl.column}>
                            {checkVisibilityOfRow(ch) &&
                                <>
                                    <Text className={cl.title} variant="xxs" color="gray-9">{ch}</Text>
                                    <div className={cl.separator} />
                                    {percents.map((p, i) =>
                                        i !== 6
                                            ?   checkSubordinateEmployees(i) && checkVisibilityOfRow(p) &&
                                            <Text
                                                key={`${ch}-${p}`}
                                                className={cn(cl.number, {
                                                    [cl.hoverElem]: hoverIdx === i
                                                })}
                                                variant="xs"
                                                color="gray-8"
                                                onMouseEnter={() => setHoverIdx(i)}
                                                onMouseLeave={() => setHoverIdx(null)}
                                            >
                                                {getNumberWithCoefficient(ch, p, i < 6)}
                                            </Text>
                                            :   checkSubordinateEmployees(i) && checkVisibilityOfRow(p) &&
                                            <React.Fragment key={`${ch}-${p}`}>
                                                <div className={cl.separator} />
                                                <Text
                                                    key={`${ch}-${p}`}
                                                    className={cn(cl.number, {
                                                        [cl.hoverElem]: hoverIdx === i
                                                    })}
                                                    variant="xs"
                                                    color="gray-8"
                                                    onMouseEnter={() => setHoverIdx(i)}
                                                    onMouseLeave={() => setHoverIdx(null)}
                                                >
                                                    {numberObj.find(no => no.length && no[0].percent === p)
                                                        ?.find(no => (no?.columnHeader ?? '') === ch)
                                                        ?.number?.toLocaleString().replaceAll(',', ' ') ?? <></>
                                                    }
                                                </Text>
                                            </React.Fragment>
                                    )}
                                </>
                            }
                        </div>
                    )}
                </div>
            </div>
        );
    };

    const renderTransposedData = () => {
        return (
            <div className={cl.trwrapper}>
                <div className={cl.trmainColumn}>
                    <div className={cl.trheaderRow}>
                        <Text className={[cl.title, cl.paddingLeft].join(' ')} variant="xxs" color="gray-9">
                            {title.replace('Сотрудник', 'СОТРУДНИКИ').replace('Компания', 'КОМПАНИИ')}
                        </Text>
                        <div className={cl.pracentTitles}>
                            {orderedPercents.map((percent, i) =>
                                    checkVisibilityOfRow(percent) && (
                                        <Text key={i} className={cn(cl.title, cl.columnNumberspace, cl.rightPosition)} variant="xxs" color="gray-9">
                                            {percent}
                                        </Text>
                                    )
                            )}
                        </div>
                    </div>

                    <div className={cl.trseparator} />
                    {columnHeaders.slice(0, showAll ? columnHeaders.length : 7).map((ch, rowIndex) =>
                        <div
                            key={ch}
                            className={cn(cl.trcolumn, { [cl.hoverElem]: hoverIdx === rowIndex })}
                            onMouseEnter={() => setHoverIdx(rowIndex)}
                            onMouseLeave={() => setHoverIdx(null)}
                        >
                            {checkVisibilityOfRow(ch) &&
                                <>
                                    <div className={cn(cl.trcolumNumber, cl.columnTitle)}>
                                        <Text variant="xxs" color="gray-9">{ch}</Text>
                                    </div>
                                    {orderedPercents.map((p, i) =>
                                            checkSubordinateEmployees(i) && checkVisibilityOfRow(p) && (
                                                <div className={cn(cl.columnNumberspace)} key={`${ch}-${p}`}>
                                                    <Text
                                                        className={cn(cl.trnumber, { [cl.hoverElem]: hoverIdx === rowIndex })}
                                                        variant="xs"
                                                        color="gray-8"
                                                    >
                                                        {getNumberWithCoefficient(ch, p, i < 6)}
                                                    </Text>
                                                </div>
                                            )
                                    )}
                                </>
                            }
                        </div>
                    )}
                    <div className={cl.trseparator} />
                    {filteredPercents.map((p, i) => {
                        const adjustedIndex = i + offset; // Вычисляем уникальный индекс для hover эффекта

                        // Проверяем, нужно ли показывать строку
                        if (checkSubordinateEmployees(i) && checkVisibilityOfRow(p)) {
                            return (
                                <div
                                    key={p} // ключ уникальный для каждой строки filteredPercents
                                    className={cn(cl.trcolumn, { [cl.hoverElem]: hoverIdx === adjustedIndex })}
                                    onMouseEnter={() => setHoverIdx(adjustedIndex)}
                                    onMouseLeave={() => setHoverIdx(null)}
                                >
                                    <div className={cn(cl.trcolumNumber, cl.columnTitle)}>
                                        <Text variant="xxs" color="gray-9">{p}</Text>
                                    </div>

                                    {/* Рендер ячеек с данными */}
                                    {columnHeaders.map((ch, headerIndex) => {
                                        // Получаем соответствующее числовое значение
                                        const number = numberObj.find(no => no.length && no[0].percent === p)
                                            ?.find(no => (no?.columnHeader ?? '') === ch)
                                            ?.number;

                                        // Рендер только первых 7 заголовков
                                        if (headerIndex < 7) {
                                            return (
                                                <div key={`${ch}-${p}-${i}`} className={cn(cl.columnNumberspace)}> {/* Уникальный ключ для каждой ячейки */}
                                                    <Text
                                                        className={cn(cl.trnumber)}
                                                        variant="xs"
                                                        color="gray-8"
                                                    >
                                                        {number ? number.toLocaleString('ru-RU').replaceAll(',', ' ') : ''}
                                                    </Text>
                                                </div>
                                            );
                                        }
                                        return null; // Возвращаем null, если элемент не нужно рендерить
                                    })}
                                </div>
                            );
                        } else {
                            return null; // Если строка не проходит проверку, не рендерим её
                        }
                    })}
                    <button className={cl.buttonmore} onClick={toggleShowAll}>
                        {showAll ? 'Показать меньше' : 'Показать больше'}
                    </button>
                </div>
            </div>
        );
    };



    return (
        <div>
            {viewMode === 'transposed' ? renderTransposedData() : renderStandardData()}
        </div>
    );
};

export default Table;
