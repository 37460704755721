import React, { FC, useEffect, useMemo, useState } from 'react';
import cl from './style.module.css';
import Text from 'shared/ui/Text';
import { Button, Checkbox, Collapse, Input } from 'antd';
import { ALPHABET } from 'shared/constants';
import { useGetFiltersQuery } from '../../model/endpoints';
import { useParams } from 'react-router-dom';
import { IOption, ISubjectOption } from '../../types';
import Region from '../Region';
import { SearchOutlined } from '@ant-design/icons';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { setCities } from 'shared/store/reducers/commonSlice';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import ExpandIcon from 'shared/assets/icons/expand.svg';
import CollapseIcon from 'shared/assets/icons/collapse.svg';
import { ReactSVG } from 'react-svg';

interface CitiesDistrictProps {
    searchValue: string;
}

const CitiesDistrict: FC<CitiesDistrictProps> = ({ searchValue }) => {
    const dispatch = useAppDispatch();
    const { reportNumber } = useParams();
    const { cities } = useAppSelector(state => state.common);
    const { data: filters } = useGetFiltersQuery(reportNumber ?? '0');
    const [selectedDistricts, setSelectedDistricts] = useState<number[]>([]);

    const [clickedActiveKey, setClickedActiveKey] = useState<string | null>(null);
    const [activeKeys, setActiveKeys] = useState<string[]>();


    const districts = useMemo(() => filters && Object.entries(filters)
        .filter(([key, _]) => key === 'districts')[0][1] as unknown as IOption[], [filters]);

    const regions = useMemo(() => filters && Object.entries(filters)
        .filter(([key, _]) => key === 'subjects')[0][1] as unknown as ISubjectOption[], [filters]);

    useEffect(() => {
        if (districts) {
            setSelectedDistricts([...districts.map(d => d.id)]);
        }
    }, [districts]);

    const filteredRegions = useMemo(() => regions?.filter(r => selectedDistricts.some(sd =>
        sd === r.district_id) && (r.subject_name.toLowerCase().startsWith(searchValue.toLowerCase()) ||
        r.cities.some(c => c.name.toString().toLowerCase()
            .startsWith(searchValue.toLowerCase())))), [searchValue, regions, selectedDistricts]);

    const filteredAlphabet = useMemo(() => ALPHABET.split('').filter(letter => filteredRegions?.some(r =>
        r.subject_name.toLowerCase().startsWith(letter.toLowerCase()))), [filteredRegions, ALPHABET]);

    const isAllSelected = useMemo(() => regions?.every(r =>
        r.cities.every(city => cities?.split(',').some(c => c === city.id.toString()))), [regions, cities]);

    const moscow = useMemo(() => regions?.find(r =>
        r.cities.find(city => city.name.toString().toLowerCase() === 'москва'))?.cities.find(city =>
        city.name.toString().toLowerCase() === 'москва'), [regions]);

    useEffect(() => {
        setActiveKeys(Array.from({ length: filteredAlphabet.length }, (_, letter) => letter.toString()));
    }, [filteredAlphabet]);

    const handleClickExpandButton = () => {
        activeKeys?.length === filteredAlphabet.length
            ? setActiveKeys([])
            : setActiveKeys(Array.from({ length: filteredAlphabet.length }, (_, letter) => letter.toString()));
        setClickedActiveKey(null);
    };

    return (
        <div className={[cl.wrapper, 'cities-district'].join(' ')}>
            <div>
                {!!moscow &&
                    <Checkbox
                        checked={cities?.split(',').some(city => city === moscow.id.toString())}
                        onChange={e => dispatch(setCities(e.target.checked
                            ? cities ? `${cities},${moscow.id}` : `${moscow.id}`
                            : cities?.split(',')
                            .filter(city => city !== moscow.id.toString())
                            .join(',') ?? '',
                        ))}
                    >
                        <Text variant="xxs" color="gray-8">Москва</Text>
                    </Checkbox>
                }
                <div className={cl.allDeleteWrapper}>
                    <Checkbox
                        checked={isAllSelected}
                        onChange={e => dispatch(setCities(e.target.checked
                            ? regions?.map(r => r.cities.map(c => c.id).join(',')).join(',') ?? ''
                            : '',
                        ))}
                    >
                        <Text variant="xxs" color="gray-8">Выбрать все</Text>
                    </Checkbox>
                    <Button size="small" onClick={() => dispatch(setCities(''))}>Очистить</Button>
                </div>
            </div>
            <div className={cl.block}>
                <Text variant="s" color="gray-10" bold>Регионы</Text>
                {!!districts &&
                    <div className={cl.line}>
                        {districts.map(d =>
                            <Checkbox
                                key={d.id}
                                checked={selectedDistricts.some(s => s === d.id)}
                                onChange={e => e.target.checked
                                    ? setSelectedDistricts([...selectedDistricts, d.id])
                                    : setSelectedDistricts([...selectedDistricts.filter(sd =>
                                        sd !== d.id)])
                                }
                            >
                                <Text variant="xxs" color="gray-8">{d.name}</Text>
                            </Checkbox>,
                        )}
                    </div>
                }
            </div>

            <div className={[cl.block, cl.blockLetter].join(' ')}>
                <div className={cl.districtsTitle}>
                    <Text variant="s" color="gray-10" bold>Области</Text>
                    <ReactSVG
                        src={activeKeys?.length === filteredAlphabet.length ? CollapseIcon : ExpandIcon}
                        role="button"
                        tabIndex={0}
                        onClick={handleClickExpandButton}
                        onKeyDown={e => e.key === 'Enter' && handleClickExpandButton()}
                    />
                </div>
                {filteredAlphabet.map((letter, alphabetIndex) =>
                    <div key={letter} className={cl.inlineBlock}>
                        <div
                            className={`${cl.customCollapsePanel} ${clickedActiveKey === alphabetIndex.toString() ? cl.myElement : ''}`}>
                            <Collapse
                                expandIconPosition="start"
                                ghost
                                activeKey={activeKeys}
                                onChange={key => {
                                    setActiveKeys(Array.isArray(key) ? key : [key]);
                                    setClickedActiveKey(key.includes(alphabetIndex.toString()) ? alphabetIndex.toString() : null);
                                }}
                            >
                                <Collapse.Panel
                                    header={
                                        <Text variant="s" color="gray-8" bold>
                                            {letter}
                                        </Text>
                                    }
                                    key={alphabetIndex}
                                >
                                    {filteredRegions?.map((r, regionIndex) => r.subject_name.startsWith(letter) &&
                                        <div key={r.district_id} className={cl.otstup}>
                                            <Region
                                                idx={alphabetIndex}
                                                letter={letter}
                                                regions={filteredRegions}
                                                region={r}
                                                clickedActiveKey={clickedActiveKey}
                                                setClickedActiveKey={setClickedActiveKey}
                                                activeKeys={activeKeys}
                                                setActiveKeys={setActiveKeys}
                                            />
                                        </div>,
                                    )}
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    </div>,
                )}


            </div>
        </div>
    );
};

export default CitiesDistrict;
