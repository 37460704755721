import { IAntdOption, IOption } from './types';

export const renameTreeForSelect = (
    tree: IOption[] | { cities: IOption[]; district_id: number; subject_name: string; }[]
): IAntdOption[] => tree.map(t => {
    if ('cities' in t) {
        return renameTreeForSelect(t.cities) as unknown as IAntdOption;
    } else {
        return t.children
            ? {
                value: t.id,
                title: t.name,
                children: renameTreeForSelect(t.children),
            }
            : {
                value: t.id,
                title: t.name,
            };
    }
});
