import { TextProps } from './ui/Text';

export const MONTHS_ARRAY = [
    'Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль',
    'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
];

export const TEXT_SIZE_ARRAY: Pick<TextProps, 'variant'>['variant'][] = [
    'subheader', 'body', 'l', 'm', 'small', 's'
];

export const ALPHABET = 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯ';

export const SET_ALL_CONSTANT_ID = -1000000;
