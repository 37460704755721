export const FiltersNameEnum = {
    'periods': {
        keyForReport: 'month_year',
        value: 'Период'
    },
    'subjects': {
        keyForReport: 'city',
        value: 'Город'
    },
    'func_groups': {
        keyForReport: 'func_group',
        value: 'Область специализации'
    },
    'levels': {
        keyForReport: 'level',
        value: 'Уровень должности'
    },
    'comp_groups': {
        keyForReport: 'comp_group',
        value: 'Группа компаний'
    },
    'quals': {
        keyForReport: 'qual',
        value: 'Уровни квалификации'
    }
} as const;

export interface IOption {
    id: number;
    name: string | number;
    children?: IOption[];
}

export interface ISubjectOption {
    id: number;
    name: string | number;
    district_id: number;
    subject_name: string;
    cities: IOption[];
    children: IOption[];
}

export interface IAntdOption {
    value: number;
    title: string | number;
    children?: IAntdOption[];
}


export interface IFuncGroupOption {
    id: number;
    name: string | number;
    value: number;
    children: IChildGroup[];
    description?: string; // Убедитесь, что это свойство опционально
  }


export interface IChildGroup {
    id: number;
    value: number;
    name: string;
    description?: string;
}

export interface IFilter {
    keyForReport: string;
    keyForFilters: string;
    title: ValueOf<typeof FiltersNameEnum>;
    options: IAntdOption[];
}

export interface ISaveTemplate {
    filter: {
        filterName: keyof typeof FiltersNameEnum;
        keys: string;
    }[];
    chain: string;
}

export interface Article {
    id: number;
    title: string;
    content: string;
}

