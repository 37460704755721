import React, { FC, HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import cl from './style.module.css';
import Text from 'shared/ui/Text';
import Table from 'shared/ui/Table';
import { Collapse, Tooltip } from 'antd';
import { useLazyGetSubReportQuery } from 'entities/report/model/endpoints';
import { useGetSettingsQuery } from 'entities/settings/model/endpoints';
import { useParams } from 'react-router-dom';
import Spinner from 'shared/ui/Spinner';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { setCurrency } from 'shared/store/reducers/commonSlice';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { FieldsNameEnum, ITreeNode } from '../../types';
import InfoCircleIcon from 'shared/assets/icons/info-circle.svg';
import { ReactSVG } from 'react-svg';
import { RootState } from '../../../../shared/store';
import { Radio, RadioChangeEvent } from 'antd';



interface ReportWindowProps {
    isOpenFilters: boolean;
    allReports: any[];  // Пропс для всех отчетов
    
        // Добавьте другие фильтры, если нужно
    };


const ReportWindow: FC<ReportWindowProps> = ({ isOpenFilters, allReports}) => {
    const dispatch = useAppDispatch();
    const { reportNumber } = useParams();
    const tableRefs = useRef<(HTMLDivElement | null)[]>([]);
    const {
        filtersChain,
        triggerForReportRequest,
        periods,
        cities,
        func_groups,
        levels,
        comp_groups,
        currency
    } = useAppSelector(state => state.common);

    const [allReportData, setAllReportData] = useState<{ [key: string]: any }>({});
    const [getReport, { isFetching: isLoadingSubReport }] = useLazyGetSubReportQuery();

    const { data: settings, isLoading: isLoadingSettings } = useGetSettingsQuery(reportNumber ?? '0');

    const isShowLevelDescription = useSelector((state: RootState) => state.common.isShowLevelDescription);
    const isShowFuncGroupDescription = useSelector((state: RootState) => state.common.isShowFuncGroupDescription);

    const [viewMode, setViewMode] = useState<'standard' | 'transposed'>('standard');

    // Предположим, что subReport хранится в состоянии:
    const [reportData, setReportData] = useState<any>(null); 


    const handleViewModeChange = (e: RadioChangeEvent) => {
        setViewMode(e.target.value);
    };

    // useEffect(() => {
    //     if(settings) {
    //         dispatch(setCurrency(settings.currency_types.raw_currency_type.type));
    //     }
    // }, [settings]);

    useEffect(() => {
        if (settings && settings.currency_types.raw_currency_type && !currency) {
            dispatch(setCurrency(settings.currency_types.raw_currency_type.type));
        }
    }, [settings, dispatch]);

    function capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    // Запрашиваем данные для всех отчетов
    useEffect(() => {
        const fetchAllReports = async () => {
            const reportPromises = allReports.map(report =>
                getReport({
                    reportNumber: report.id,  // Используем id каждого отчета
                    filtersChain,
                    periods,
                    cities,
                    func_groups,
                    levels,
                    comp_groups,
                }).unwrap() // Добавляем unwrap() для получения данных из query
                  .then((response) => {
                    console.log(`Данные отчета ${report.id}:`, response);  // Логируем данные отчета
                    setReportData(response);
                    return { id: report.id, data: response };
                })
                  .catch((error) => {
                      console.error(`Ошибка загрузки данных отчета ${report.id}:`, error);
                      return { id: report.id, data: null };  // Возвращаем пустой объект на случай ошибки
                  })
            );

            const results = await Promise.all(reportPromises);

            const reportDataMap = results.reduce((acc, result) => {
                if (result.data) {
                    acc[result.id] = result.data;
                }
                return acc;
            }, {} as { [key: string]: any });

            console.log("Все данные отчетов:", reportDataMap);
            setAllReportData(reportDataMap);
        };

        fetchAllReports();
    }, [triggerForReportRequest, allReports]);

    useEffect(() => {
        const handleScroll = (index: number) => {
            const scrollLeft = tableRefs.current[index]?.scrollLeft || 0;
            tableRefs.current.forEach((div, i) => {
                if (i !== index && div) {
                    div.scrollLeft = scrollLeft;
                }
            });
        };

        tableRefs.current.forEach((div, index) => {
            div?.addEventListener('scroll', () => handleScroll(index));
        });

        return () => {
            tableRefs.current.forEach((div, index) => {
                div?.removeEventListener('scroll', () => handleScroll(index));
            });
        };
    }, [tableRefs.current.length]);


    const getComp = (
        height: number,
        arrLength: number,
        title?: string | number,
        node?: ITreeNode,
        collapseKey?: number,
        districtCoefficient?: number,
        countMonths?: number,
        isShowFuncGroupDescription?: boolean,
    ): ReactNode => {
        if (!node) return <></>;

        const getReturnComp = (): React.ReactNode => (
            Object.values(node.values ?? {}).find(v => (v as ITreeNode).type === FieldsNameEnum.ENTITY_TYPE)
                ? <div key={title} className={cl.tables} ref={(el) => tableRefs.current[tableRefs.current.length] = el}>
                    {Object.entries(node.values ?? {}).map(([key, value], i) =>
                        getComp(
                            height + 1,
                            Object.keys(node.values ?? {}).length,
                            key,
                            value as ITreeNode,
                            i,
                            districtCoefficient,
                            countMonths,
                            isShowFuncGroupDescription,
                        ),
                    )}
                </div>
                : Object.entries(node.values ?? {}).map(([key, value], i) =>
                    getComp(
                        height + 1,
                        Object.keys(node.values ?? {}).length,
                        key,
                        value as ITreeNode,
                        i,
                        districtCoefficient,
                        countMonths,
                        isShowFuncGroupDescription,
                    ),
                )
        );

        // const getDefaultActiveKey = () => Object.values(node.values ?? {}).map((_, i) => i.toString());

        switch (node.type) {
            case FieldsNameEnum.MONTH_YEAR:
                // const date = getCorrectDate(title ?? '', false, false);
                // const countMonths = differenceInMonths(new Date(settings?.current_date ?? ''), new Date(date));
                const month = title?.toString().substring(0, 1); // Получаем "7"
                const year = title?.toString().substring(1);
                const dateString = `${year}-${month?.padStart(2, '0')}`;
                const date = new Date(dateString);
                const monthName = capitalizeFirstLetter(date.toLocaleDateString('ru-RU', { month: 'long' }));
                return (
                    <Collapse
                        key={title}
                        expandIconPosition="end"
                        defaultActiveKey={Array.from({ length: arrLength }, (_, i) => i.toString())}
                        ghost
                    >
                        <Collapse.Panel
                            className={cl.collapse}
                            header={
                                <div className={cl.block}>
                                    <Text className={cl.title} variant="small" color="gray-10">{monthName} {year}</Text>
                                    {!!node.description &&
                                        <Tooltip
                                            className={cl.tooltip}
                                            placement="bottom"
                                            title={<Text variant="xs" color="white">{node.description}</Text>}
                                            overlayStyle={{ maxWidth: 400 }}
                                        >
                                            <div className={cl.infoCircle}>
                                                <ReactSVG src={InfoCircleIcon} />
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            <div className={cl.separator} />
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            case FieldsNameEnum.CITY:
                districtCoefficient = settings?.cities_rates.find(c => c.id === node.id)?.regional_rate ?? 1;

                return (
                    <Collapse
                        key={title}
                        expandIconPosition="end"
                        defaultActiveKey={Array.from({ length: arrLength }, (_, i) => i.toString())}
                        ghost
                    >
                        <Collapse.Panel
                            className={cl.collapse}
                            header={
                                <div className={cl.block}>
                                    <Text className={cl.title} variant="small" color="brand-2">{title}</Text>
                                    {!!node.description &&
                                        <Tooltip
                                            className={cl.tooltip}
                                            placement="bottom"
                                            title={<Text variant="xs" color="white">{node.description}</Text>}
                                            overlayStyle={{ maxWidth: 400 }}
                                        >
                                            <div className={cl.infoCircle}>
                                                <ReactSVG src={InfoCircleIcon} />
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            <div className={cl.separator} />
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            case FieldsNameEnum.FUNC_GROUP_ROOT:
                return (
                    <Collapse
                        key={title}
                        expandIconPosition="end"
                        defaultActiveKey={Array.from({ length: arrLength }, (_, i) => i.toString())}
                        ghost
                    >
                        <Collapse.Panel
                            className={cl.collapse}
                            header={
                                <div className={cl.block}>
                                    <Text className={cl.title} variant="small" color="gray-7">{title}</Text>
                                    {!!node.description &&
                                        <Tooltip
                                            className={cl.tooltip}
                                            placement="bottom"
                                            title={<Text variant="xs" color="white">{node.description}</Text>}
                                            overlayStyle={{ maxWidth: 400 }}
                                        >
                                            <div className={cl.infoCircle}>
                                                <ReactSVG src={InfoCircleIcon} />
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            <div className={cl.separator} />
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            case FieldsNameEnum.FUNC_GROUP:
                return (
                    <Collapse
                        key={title}
                        expandIconPosition="end"
                        defaultActiveKey={Array.from({ length: arrLength }, (_, i) => i.toString())}
                        ghost
                    >
                        <Collapse.Panel
                            className={cl.collapse}
                            header={
                                <div className={cl.block}>
                                    <Text className={cl.title} variant="small" color="gray-10">{title}</Text>
                                    {/* Показываем описание только если isShowFuncGroupDescription === true */}
                                    {isShowFuncGroupDescription && !!node.description &&
                                        <Tooltip
                                            className={cl.tooltip}
                                            placement="bottom"
                                            title={<Text variant="xs" color="white">{node.description}</Text>}
                                            overlayStyle={{ maxWidth: 400 }}
                                        >
                                            <div className={cl.infoCircle}>
                                                <ReactSVG src={InfoCircleIcon} />
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            <div className={cl.separator} />
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            case FieldsNameEnum.LEVEL:
                return (
                    <Collapse
                        key={title}
                        expandIconPosition="end"
                        defaultActiveKey={Array.from({ length: arrLength }, (_, i) => i.toString())}
                        ghost
                    >
                        <Collapse.Panel
                            className={cl.collapse}
                            header={
                                <div className={cl.block}>
                                    <Text className={cl.title} variant="small" color="gray-10">{title}</Text>
                                    {isShowLevelDescription && !!node.description &&
                                        <Tooltip
                                            className={cl.tooltip}
                                            placement="bottom"
                                            title={<Text variant="xs" color="white">{node.description}</Text>}
                                            overlayStyle={{ maxWidth: 400 }}
                                        >
                                            <div className={cl.infoCircle}>
                                                <ReactSVG src={InfoCircleIcon} />
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            <div className={cl.separator} />
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            case FieldsNameEnum.COMP_GROUP:
                return (
                    <Collapse
                        key={title}
                        expandIconPosition="end"
                        defaultActiveKey={Array.from({ length: arrLength }, (_, i) => i.toString())}
                        ghost
                    >
                        <Collapse.Panel
                            className={cl.collapseGroup}
                            header={
                                <div>
                                    <Text
                                        className={[cl.block, cl.title].join(' ')}
                                        variant="xxs"
                                        color="gray-10"
                                    >
                                        {title}
                                    </Text>
                                </div>
                            }
                            key={collapseKey ?? 0}
                        >
                            {getReturnComp()}
                        </Collapse.Panel>
                    </Collapse>
                );
            case FieldsNameEnum.ENTITY_TYPE:
                const values = Object.entries(node?.values ?? {})
                    .filter(([key, _]) => key === 'values')
                    .map(([_, value]) => value)[0];

                if (Array.isArray(values)) {
                    const numberObj = values.map(val =>
                        val[1].map(v => ({
                            percent: val[0] as unknown as string,
                            columnHeader: v[0] as string,
                            number: v[1] as number | null,
                        })));

                    return (
                        <Table
                            key={title}
                            title={title?.toString() ?? ''}
                            numberObj={numberObj}
                            percents={values.map(v => v[0]) as unknown as string[]}
                            columnHeaders={values.map(v => v[1])[0].map(v => v[0]) as string[]}
                            districtCoefficient={districtCoefficient ?? 1}
                            // countMonths={(countMonths ?? 0) < 0 ? 0 : (countMonths ?? 0)}
                            viewMode={viewMode}
                        />
                    );
                }
                return <></>;
            default:
                return <React.Fragment key={title} />;
        }
    };

    return (
        <div className={isOpenFilters ? cl.wrapper : [cl.wrapper, cl.wrapperFull].join(' ')}>
            <div className={cl.radioGroupMargin}>
                <Radio.Group onChange={handleViewModeChange} value={viewMode}>
                    <Radio value="standard">Стандартный</Radio>
                    <Radio value="transposed">Транспонированный</Radio>
                </Radio.Group>
            </div>
            <div className={cl.window}>
                {isLoadingSubReport || isLoadingSettings
                    ? <Spinner size="l" />
                    : Object.entries(reportData?.tree ?? {}).map(([key, value], i, arr) =>
                        getComp(0, arr.length, key, value as ITreeNode, i, undefined, undefined, isShowFuncGroupDescription),
                    )
                }
            </div>
        </div>

    );
};

export default ReportWindow;
