import { apiSlice } from 'shared/store/api';
import { SettingsResponse, settingsSchema } from './schemas';
import { ReconciliationFilled } from '@ant-design/icons';

const settingsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getSettings: builder.query<SettingsResponse, string>({
            query: reportNumber => `/report/${reportNumber}/settings_info/`,
            providesTags: ['SettingsSalary'],
            extraOptions: {
                error: 'Не удалось загрузить список настроек!',
                schema: settingsSchema
            }
        }),

        changeSettings: builder.mutation<void, {
            reportNumber: string;
            field: string;
            value: any;
            rate?: number;
        }>({
            query: args => ({
                url: `/report/${args.reportNumber}/settings_info/`,
                method: 'PATCH',
                body: {
                    [args.field]: args.value,
                    rate: args.rate,
                }
            }),
            invalidatesTags: ['SettingsSalary'],
            extraOptions: {
                // success: 'Курс изменен!',
            }
        })
    })
})

export const {
    useGetSettingsQuery, useChangeSettingsMutation
} = settingsApiSlice;
