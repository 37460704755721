import { IOption } from '../types';
import { array, Infer, lazy, number, object, string, Type, null as myzodNull } from 'myzod';

const filterData: Type<IOption> = object({
    id: number(),
    name: string().or(number()),
    description: myzodNull().or(string()).optional(), // Использование null().or(string()) для описания
    children: array(lazy(() => filterData)).optional()
});

const subjectData = object({
    district_id: number(),
    subject_name: string(),
    cities: array(filterData)
});

export const filtersSchema = object({
    periods: array(filterData),
    districts: array(filterData),
    subjects: array(subjectData),
    func_groups: array(filterData),
    levels: array(filterData),
    comp_groups: array(filterData),
    qual: array(filterData),
});

export const templatesSchema = array(object({
    id: number(),
    name: string()
}));

export const templateSchema = object({
    id: number(),
    name: string(),
    json_data: string()
});

export type FiltersResponse = Infer<typeof filtersSchema>;

export type TemplatesResponse = Infer<typeof templatesSchema>;

export type TemplateResponse = Infer<typeof templateSchema>;
