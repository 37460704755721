import React, { FC, HTMLAttributes, useCallback, useEffect, useState } from 'react';
import cl from './style.module.css';
import Filter from 'entities/filter/components/Filter';
import Text from 'shared/ui/Text';
import { IAntdOption, IFilter } from 'entities/filter/types';
import { useLazyGetFiltersQuery } from 'entities/filter/model/endpoints'; // Оставляем только lazy-запрос
import { FiltersNameEnum } from 'entities/filter/types';
import Spinner from 'shared/ui/Spinner';
import { renameTreeForSelect } from 'entities/filter/utils';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { Modal, Button } from 'antd';
import MovableSidebar from 'shared/ui/MovableSidebar';
import { setFiltersChain, setTriggerForReportRequest } from 'shared/store/reducers/commonSlice';
import { useScreenWidth } from 'shared/hooks/useScreenWidth';

interface FiltersProps extends HTMLAttributes<HTMLDivElement> {
    isOpenFilters: boolean;
    setIsOpenFilters: (value: boolean) => void;
    isOpenCities: boolean;
    isOpenFuncGroup: boolean;
    setIsOpenCities: (value: boolean) => void;
    setIsOpenFuncGroup: (value: boolean) => void;
    template?: any;
    triggerTemplate?: boolean;
    allReports: any[];  // Список всех отчётов
}

const Filters: FC<FiltersProps> = ({
    isOpenFilters,
    setIsOpenFilters,
    isOpenCities,
    setIsOpenCities,
    isOpenFuncGroup,
    setIsOpenFuncGroup,
    template,
    triggerTemplate,
    allReports,
}) => {
    const width = useScreenWidth();
    const dispatch = useAppDispatch();
    const [filtersProps, setFiltersProps] = useState<IFilter[]>([]);
    const [triggerFetchFilters] = useLazyGetFiltersQuery();  // Lazy запрос
    const [isLoadingFilters, setIsLoadingFilters] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [initialFiltersProps, setInitialFiltersProps] = useState<IFilter[]>([]);
    const [isError, setIsError] = useState(false);

    const handleFilterChange = useCallback(() => {
        setIsButtonDisabled(false);
    }, []);

    // Запрашиваем фильтры для всех отчётов из allReports
    useEffect(() => {
        if (allReports.length) {
            setIsLoadingFilters(true);

            // Выполняем запросы для всех отчётов параллельно
            Promise.all(
                allReports.map((report) => 
                    triggerFetchFilters(report.id)
                        .unwrap()
                        .catch((error) => {
                            console.error(`Ошибка при загрузке фильтров для отчета ${report.id}:`, error);
                            return null; // Чтобы запросы продолжались даже при ошибке одного
                        })
                )
            ).then((filtersDataArray) => {
                const combinedFilters = filtersDataArray
                .filter(data => data !== null)  // Фильтруем null значения
                .reduce((acc, filtersData) => {
                    if (!filtersData) return acc;  // Проверяем, что filtersData не null
            
                    Object.entries(filtersData).forEach(([key, value]) => {
                        if (!acc[key]) {
                            acc[key] = [];
                        }
            
                        // Добавляем уникальные значения
                        value.forEach((item: any) => {
                            if (!acc[key].some((existingItem: any) => JSON.stringify(existingItem) === JSON.stringify(item))) {
                                acc[key].push(item);
                            }
                        });
                    });
            
                    return acc;
                }, {} as Record<string, any[]>);  // Используем Record для указания типа
            
            console.log('Собранные фильтры:', combinedFilters);

                // Обрабатываем фильтры и обновляем состояние
                const filteredProps = Object.entries(combinedFilters)
                    .filter(([key, _]) => key !== 'districts' && key !== 'qual')  // Исключаем 'districts' и 'qual'
                    .map(([key, value]) => {
                        const arr: IAntdOption[] = [];
                        const options = renameTreeForSelect(value);
                        if (options?.length && Array.isArray(options[0])) {
                            options.forEach(opt => Array.isArray(opt)
                                ? opt.forEach(o => arr.push(o))
                                : arr.push(opt)
                            );
                        }
                        return ({
                            keyForReport: FiltersNameEnum[key as keyof typeof FiltersNameEnum].keyForReport,
                            keyForFilters: key,
                            title: FiltersNameEnum[key as keyof typeof FiltersNameEnum],
                            options: arr.length ? arr : options
                        });
                    });

                setFiltersProps(filteredProps);
                setIsLoadingFilters(false);
            }).catch((error) => {
                console.error("Ошибка при сборе всех фильтров:", error);
                setIsLoadingFilters(false);
            });
        }
    }, [allReports, triggerFetchFilters]);

    useEffect(() => {
        dispatch(setFiltersChain(filtersProps.map(fp => fp.keyForReport).join(',')));
    }, [filtersProps]);

    useEffect(() => {
        if (template && template.json_data) {
            const jsonData = JSON.parse(template.json_data);
            if (jsonData && jsonData.chain) {
                const chain: string[] = jsonData.chain.split(',');
                setFiltersProps(chain.map(c => filtersProps.find(fp => fp.keyForReport === c) ?? filtersProps[0]));
            }
        }
    }, [template, triggerTemplate, filtersProps]);

    const handleClick = () => {
        let allFiltersSelected = true;

        for (const fp of filtersProps) {
            const localData = localStorage.getItem(`filter_${fp.keyForFilters}`);
        
            if (!localData || JSON.parse(localData).length === 0) {
                allFiltersSelected = false;
                break; // Прерываем цикл, как только найдём невыбранный фильтр
            }
        }

        if (!allFiltersSelected) {
            Modal.error({
                title: 'Ошибка',
                content: 'Выбраны не все параметры фильтрации',
            });
            return;
        }

        setIsError(false);
        dispatch(setTriggerForReportRequest());
        setIsButtonDisabled(true);
        if (width <= 768) {
            setIsOpenFilters(!isOpenFilters);
        }
    }

    return (
        <MovableSidebar
            className={cl.filters}
            isOpenContent={isOpenFilters}
            setIsOpenContent={setIsOpenFilters}
            showArrowText="Показать фильтры"
            hideArrowText="Скрыть фильтры"
        >
            <div className={cl.innerWrapper}>
                <Text className={cl.title} variant="s" color="primary">Фильтры</Text>
                {isLoadingFilters
                    ?   <div className={cl.spinner}>
                            <Spinner size="s" />
                        </div>
                    :   !!filtersProps.length && filtersProps.map((fp, i) =>
                            <Filter
                                key={fp.keyForFilters}
                                title={fp.title.value}
                                titleKey={fp.keyForFilters}
                                index={i}
                                options={fp.options}
                                cbDrag={handleFilterChange}
                                template={template}
                                triggerTemplate={triggerTemplate}
                                setIsOpenCitiesSort={() => setIsOpenCities(!isOpenCities)}
                                setIsOpenFuncGroupSort={() => setIsOpenFuncGroup(!isOpenFuncGroup)}
                                onFilterChange={handleFilterChange}
                            />
                    )
                }
            </div>
            <Button size="large" className={cl.applyButton} type="primary" onClick={handleClick} disabled={isButtonDisabled}>
                Сформировать отчет
            </Button>
        </MovableSidebar>
    );
};

export default Filters;
