import React, { FC, useMemo, useState, useCallback } from 'react';
import cl from './style.module.css';
import Text from 'shared/ui/Text';
import { Button, Checkbox, Collapse, Tooltip } from 'antd';
import { setFuncGroups } from 'shared/store/reducers/commonSlice';
import { IFuncGroupOption, IChildGroup } from '../../types';
import { useGetFiltersQuery } from '../../model/endpoints';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { useParams } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import InfoCircleIcon from 'shared/assets/icons/info-circle.svg';
import { ReactSVG } from 'react-svg';


interface FuncGroupProps {
    searchValue: string;
}

interface ChildGroup {
    id: number;
    value: number;
    name: string;
}

// interface ISubjectOption {
//     id: number;
//     name: string;
//     children: ChildGroup[]; // Соответствует интерфейсу ChildGroup
//     // Дополните интерфейс другими свойствами, если они есть
// }


const FuncGroup: FC<FuncGroupProps> = ({ searchValue }) => {
    const dispatch = useAppDispatch();
    const { reportNumber } = useParams();
    const { func_groups } = useAppSelector(state => state.common);
    const { data: filters } = useGetFiltersQuery(reportNumber ?? '0');
    const [activeKey, setActiveKey] = useState<string | null>(null);


    const func = useMemo(() => filters && Object.entries(filters)
        .filter(([key, _]) => key === 'func_groups')[0][1] as unknown as IFuncGroupOption[], [filters]);

    const isAllSelected = useMemo(() => {
        const allFuncIds = func?.flatMap(f => [...f.children.map(child => child.id)]);
        const selectedFuncIds = func_groups?.split(',');
        return allFuncIds?.every(id => selectedFuncIds?.includes(id.toString())) ?? false;
    }, [func, func_groups]);


    const filteredFuncGroups = useMemo(() => {
        const lowerCaseSearchValue = searchValue.toLowerCase();
        return func?.map(group => ({
            ...group,
            children: group.children.filter(child =>
                typeof child.name === 'string' && child.name.toLowerCase().includes(lowerCaseSearchValue),
            ),
        })).filter(group =>
            typeof group.name === 'string' && group.name.toLowerCase().includes(lowerCaseSearchValue) ||
            group.children.length > 0,
        );
    }, [func, searchValue]);


    const updateFuncGroups = (newSelectedIds: string[]) => {
        // Убедимся, что передаём пустую строку, если массив пуст
        const newFuncGroupsValue = newSelectedIds.length > 0 ? newSelectedIds.join(',') : '';
        dispatch(setFuncGroups(newFuncGroupsValue));
    };


    const handleSelectAll = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            // Проверяем, определена ли func перед вызовом flatMap
            const allIds = func ? func.flatMap(group => [...group.children.map(child => child.id.toString())]) : [];
            updateFuncGroups(allIds);
        } else {
            updateFuncGroups([]);
        }
    };


    const handleClearAll = () => {
        dispatch(setFuncGroups('')); // Устанавливаем пустую строку для func_groups
    };


    // Функция для проверки, выбрана ли дочерняя группа или её родитель
    const isGroupChecked = (groupId: number, parentGroupId?: number) => {
        const selectedIds = new Set(func_groups?.split(',').map(Number));
        return selectedIds.has(groupId) || (parentGroupId !== undefined && selectedIds.has(parentGroupId));
    };

    const isAllGroupSelected = useCallback((children: IChildGroup[]) => children.every(c =>
        func_groups?.split(',').some(func_group => func_group === c.id.toString())), [func_groups]);

    const isNotAllGroupSelected = useCallback((children: IChildGroup[]) => !!func_groups && children.some(c =>
        func_groups.split(',').some(func_group => func_group === c.id.toString())), [func_groups]);

    return (
        <div className={cl.wrapper}>
            <div>
                <div className={cl.allDeleteWrapper}>
                    <Checkbox
                        checked={isAllSelected}
                        onChange={handleSelectAll}
                    >
                        <Text variant="xxs" color="gray-8">Выбрать все</Text>
                    </Checkbox>
                    <Button size="small" onClick={handleClearAll}>Очистить</Button>
                </div>
            </div>
            <div className={[cl.block, cl.blockLetter].join(' ')}>
                {filteredFuncGroups?.map(group => (
                    <div key={group.id} className={cl.groupWrapper}>
                        <div className={`${cl.customCollapsePanel} ${activeKey === group.id.toString() ? cl.myElement : ''}`}>
                            <Collapse
                                className={cl.customCollapse}
                                expandIconPosition="start"
                                ghost
                                onChange={key => setActiveKey(Array.isArray(key) ? key[0] : key)}
                            >
                                <Collapse.Panel
                                    key={group.id}
                                    header={
                                        <Checkbox
                                            indeterminate={!isAllGroupSelected(group.children) && isNotAllGroupSelected(group.children)}
                                            checked={isAllGroupSelected(group.children)}
                                            onClick={e => {
                                                setActiveKey(group.id.toString());
                                                e.stopPropagation();
                                            }}
                                            onChange={e =>
                                                dispatch(setFuncGroups(e.target.checked
                                                    ? func_groups
                                                        ? `${func_groups},${group.children
                                                            .filter(c => !func_groups.includes(c.id.toString()))
                                                            .map(c => c.id)
                                                            .join(',')}`
                                                        : group.children.map(c => c.id).join(',')
                                                    : func_groups?.split(',').filter(func_group =>
                                                        !group.children.some(c => c.id.toString() === func_group)).join(',') ?? '',
                                                ))}
                                        >
                                            <Text variant="xs" className={cl.groupHeader} color="gray-8" medium>
                                                {group.name}
                                            </Text>
                                        </Checkbox>
                                    }
                                >
                                    <div className={cl.childGroupsContainer}>
                                        {group.children?.map((child: IChildGroup) => (
                                            <Checkbox
                                                key={child.id}
                                                onClick={() => setActiveKey(group.id.toString())}
                                                checked={isGroupChecked(child.id, group.id)}
                                                onChange={e => {
                                                    dispatch(setFuncGroups(e.target.checked
                                                        ? func_groups ? `${func_groups},${child.id}` : `${child.id}`
                                                        : func_groups?.split(',')
                                                        .filter(func_group => func_group !== child.id.toString())
                                                        .join(',') ?? '',
                                                    ));
                                                }}
                                            >
                                                <div className={cl.flexrow}>
                                                    <Text variant="xxs" className={cl.childGroupHeader}
                                                          color="gray-8">{child.name}</Text>

                                                    <Tooltip
                                                        title={<Text variant="xxs" color="white">{child.description}</Text>}
                                                        placement="right"
                                                    >
                                                        <div className={cl.infoCircle}>
                                                            <ReactSVG src={InfoCircleIcon} />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </Checkbox>
                                        ))}

                                    </div>
                                </Collapse.Panel>
                            </Collapse>
                        </div>
                    </div>
                ))}


            </div>
        </div>
    );
};

export default FuncGroup;
