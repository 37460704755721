import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api";
import commonReducer from 'shared/store/reducers/commonSlice'

const rootReducer = combineReducers({
    common: commonReducer,
    [apiSlice.reducerPath]: apiSlice.reducer
});

export const setupStore = () => configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(apiSlice.middleware)
});

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
