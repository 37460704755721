import { RefObject, useEffect } from 'react';
import { debounce } from 'lodash';

const useResize = (ref: RefObject<HTMLElement> | undefined, cb: () => void, isNeedDebounce = false) => {
    useEffect(() => {
        if(!ref?.current) return;

        const debouncedCb = debounce(cb, 300);

        const resizeObserver = new ResizeObserver(() => {
            if(isNeedDebounce) {
                debouncedCb();
            } else {
                cb();
            }
        });
        resizeObserver.observe(ref.current);

        return () => {
            resizeObserver.disconnect();
            debouncedCb.cancel();
        };
    }, []);
};

export default useResize;
