import { MONTHS_ARRAY } from './constants';

export const getCorrectDate = (date: string | number, capitalizeFirstLetter = false, displayDate = true) => {
    if(date.toString().length >= 5) {
        let month = date.toString().substring(0, date.toString().length - 4);
        const year = date.toString().substring(date.toString().length - 4);
        if(!displayDate) {
            month = month.length === 1 ? `0${month}` : month;
        }
        const str =  displayDate ? MONTHS_ARRAY[Number(month) - 1] + ' ' + year : `${year}-${month}-01`;
        return capitalizeFirstLetter
            ?   str.charAt(0).toUpperCase() + str.slice(1)
            :   str;
    }
    return date;
};

export const calculateMedian = (values: (number | null)[]) => {
    const filteredValues = values.filter(v => v) as number[];
    if(!filteredValues.length) return null;

    filteredValues.sort((a, b) => a - b);
    const half = Math.floor(filteredValues.length / 2);

    return filteredValues.length % 2
        ? filteredValues[half]
        : (filteredValues[half - 1] + filteredValues[half]) / 2.0;
}

export const exhaustiveCheck = (param: never): never => {
    throw new Error( 'Error in exhaustive check!' );
}
