import React, { FC, useEffect, useState } from 'react';
import cl from './style.module.css';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import Text from 'shared/ui/Text';
import { Checkbox } from 'antd';
import { useParams } from 'react-router-dom';
import { IOption } from '../../../../entities/filter/types';
import { useGetFiltersQuery } from '../../../../entities/filter/model/endpoints';

import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import {
    setDisplayObjectField,
    setIsShowCompanyTable,
    setIsShowEmployeeTable,
    setAllDisplayObjectValues,
    setIsShowAverage,
    setIsShowFuncGroupDescription,
    setIsShowLevelDescription,
    setIsShowCompanyCount,
} from 'shared/store/reducers/commonSlice';

const Display: FC = () => {
    const dispatch = useAppDispatch();
    const { reportNumber } = useParams();
    const { data: filters, isLoading: isLoadingFilters } = useGetFiltersQuery(reportNumber ?? '0');
    const {
        percentsInTable,
        columnHeadersInTable,
        displayObject,
        isShowCompanyTable,
        isShowEmployeeTable,
        isShowFuncGroupDescription,
        isShowLevelDescription,
        qual,
    } = useAppSelector(state => state.common);

    const [isAllSelected, setIsAllSelected] = useState(true);

    let leftColumnItems: JSX.Element[] = [];
    let rightColumnItems: JSX.Element[] = [];
    let thirdColumnItems: JSX.Element[] = [];


    // это на времия

    const [isCompanyDataShown, setIsCompanyDataShown] = useState(true);
    const [isHighQualificationShown, setIsHighQualificationShown] = useState(true);
    const [isStandardQualificationShown, setIsStandardQualificationShown] = useState(true);
    const [isRewardPercentageShown, setIsRewardPercentageShown] = useState(false);
    const [isAllQualificationsShown, setIsAllQualificationsShown] = useState(false);

    
    console.log('Qualifications Data:', qual);
    const qualificationsArray = qual ? qual.split(',') : [];

    const handleFuncGroupDescriptionChange = (e: any) => {
        dispatch(setIsShowFuncGroupDescription(e.target.checked));
    };

   
    const handleSelectAll = (isChecked: boolean) => {
        const updatedDisplayObject = displayObject.map(item => ({
            ...item,
            value: columnHeadersInTable.includes(item.key) ? isChecked : item.value
        }));
        dispatch(setAllDisplayObjectValues(updatedDisplayObject));
    };

    const shouldMoveToRightColumn = columnHeadersInTable.length >= 7;
    
    if (shouldMoveToRightColumn) {
        for (let i = 0; i < columnHeadersInTable.length; i++) {
            const ch = columnHeadersInTable[i];
            const checkbox = (
                <Checkbox
                    key={ch}
                    checked={displayObject.find(d => d.key === ch)?.value ?? true}
                    onChange={() => handleCheckboxChange(ch)}
                >
                    <Text variant="xxs" color="gray-8">{ch}</Text>
                </Checkbox>
            );
    
            if (i < 7) {
                leftColumnItems.push(checkbox);
            } else if (i < 14) {
                rightColumnItems.push(checkbox);
            } else {
                thirdColumnItems.push(checkbox);
            }
        }
    }


    const takecountofcompany = percentsInTable
    .filter(p =>
        p !== '90%' &&
        p !== '75%' &&
        p !== '50%' &&
        p !== '25%' &&
        p !== '10%' &&
        p !== 'Среднее'
    
    )


    // Удаление нежелательных элементов и добавление новых
    const updatedPercentsInTable = percentsInTable
    .filter(p => 
        p !== 'Кол-во сотрудников' && 
        p !== 'Подчинённых сотр.' && 
        p !== 'Кол-во компаний' &&
        p !== '% от Доход в год'
    )


    const nameMappingcomp: { [key: string]: string } = {
        'Кол-во компаний': 'Кол-во компаний',
        'Подчинённых сотр.': 'Количество подчиненных',
        'Кол-во сотрудников': 'Кол-во сотрудников',
    };


    const nameMapping: { [key: string]: string } = {
        '90%': '90% (9-й децинтиль)',
        '75%': '75% (3-й квартиль)',
        '50%': '50% (медиана)',
        '25%': '25% (1-й квартиль)',
        '10%': '10% (1-й децинтиль)',
        'Среднее': 'Среднее арифметическое',
    };

    const handleCompanyCountChange = (e: any) => {
        dispatch(setIsShowCompanyCount(e.target.checked));
    };

    const handleLevelDescriptionChange = (e: any) => {
        dispatch(setIsShowLevelDescription(e.target.checked));
    };

    useEffect(() => {
        const areAllColumnHeadersSelected = columnHeadersInTable.every(ch => 
            displayObject.find(d => d.key === ch)?.value
        );
        setIsAllSelected(areAllColumnHeadersSelected);
    }, [columnHeadersInTable, displayObject]);

    const handleCheckboxChangequal = (key: string | number) => {
        // Ваша логика обработки
    };

    const handleCheckboxChange = (oldName: string) => {
        dispatch(setDisplayObjectField(oldName));

        // Проверяем, является ли ключ "Среднее арифметическое"
        if (oldName === 'Среднее') {
            dispatch(setIsShowAverage(!displayObject.find(d => d.key === 'Среднее')?.value));
        }
    };

    return (
        <div className={cl.wrapper}>
            <div className={cl.block}>
                <div className={cl.column}>
                    <Text className={cl.title} variant="s" color="gray-8" bold>Показатели</Text>
                    {updatedPercentsInTable.map(oldName => {
                        const newName = nameMapping[oldName] || oldName;
                        const isChecked = displayObject.find(d => d.key === oldName)?.value ?? false;

                        return (
                            <Checkbox
                                key={oldName}
                                checked={isChecked}
                                onChange={() => handleCheckboxChange(oldName)}
                            >
                                <Text variant="xxs" color="gray-8">{newName}</Text>
                            </Checkbox>
                        );
                    })}

                </div>

                <div className={cl.column}>
                    <Text className={cl.title} variant="s" color="gray-8" bold>Отображать</Text>
                    <Checkbox
                       checked={isCompanyDataShown}
                       onChange={e => setIsCompanyDataShown(e.target.checked)}
                   >
                       <Text variant="xxs" color="gray-8">Данные компании</Text>
                    </Checkbox>
                    <Checkbox
                         checked={isShowFuncGroupDescription}
                         onChange={handleFuncGroupDescriptionChange}
                     >
                         <Text variant="xxs" color="gray-8">Описание областей</Text>
                    </Checkbox>
                    <Checkbox
                        checked={isShowLevelDescription}
                        onChange={handleLevelDescriptionChange}
                    >
                        <Text variant="xxs" color="gray-8">Описание уровней</Text>
                    </Checkbox>
                    {takecountofcompany.map(oldName => {
                        const newName = nameMappingcomp[oldName] || oldName;
                        const isChecked = displayObject.find(d => d.key === oldName)?.value ?? false;

                        return (
                            <Checkbox
                                key={oldName}
                                checked={isChecked}
                                onChange={() => handleCheckboxChange(oldName)}
                            >
                                <Text variant="xxs" color="gray-8">{newName}</Text>
                            </Checkbox>
                        );
                    })}

                    
                </div>

                <div  className={cl.column}>
                    <Text className={cl.title} variant="s" color="gray-8" bold>Уровни квалификации</Text>
                    {/* {filters?.qual?.map((q: IOption) => {
                        const isChecked = displayObject.find(d => d.key.toString() === q.id.toString())?.value ?? false;
                        return (
                            <Checkbox key={q.id} checked={isChecked} onChange={() => handleCheckboxChangequal(q.id.toString())}>
                                <Text variant="xxs" color="gray-8">{q.name}</Text>
                            </Checkbox>
                        );
                    }) || null} */}
                    <Checkbox
                    checked={isStandardQualificationShown}
                    onChange={e => setIsStandardQualificationShown(e.target.checked)}
                        >
                            <Text variant="xxs" color="gray-8">Стандартная квалификация</Text>
                        </Checkbox>

                        {/* Чекбокс для 'Высокая квалификация' */}
                        <Checkbox
                            checked={isHighQualificationShown}
                            onChange={e => setIsHighQualificationShown(e.target.checked)}
                        >
                            <Text variant="xxs" color="gray-8">Высокая квалификация</Text>
                        </Checkbox>
                
                        <Checkbox
                            checked={isAllQualificationsShown}
                            onChange={e => setIsAllQualificationsShown(e.target.checked)}
                        >
                            <Text variant="xxs" color="gray-8">Все квалификации</Text>
                        </Checkbox>

                        <br />
                        <div  className={cl.column}>
                            <Text className={cl.title} variant="s" color="gray-8" bold>Способы расчета</Text>
                            <Checkbox
                                checked={isShowCompanyTable}
                                onChange={e => dispatch(setIsShowCompanyTable(e.target.checked))}
                            >
                                <Text variant="xxs" color="gray-8">Расчет по компаниям</Text>
                            </Checkbox>
                            <Checkbox
                                checked={isShowEmployeeTable}
                                onChange={e => dispatch(setIsShowEmployeeTable(e.target.checked))}
                            >
                                <Text variant="xxs" color="gray-8">Расчет по сотрудникам</Text>
                            </Checkbox>
                        </div>
                    </div>
            </div>
            

            <div className={cl.separator} />

            {shouldMoveToRightColumn && (
    <div className={cl.block}>
        <div className={cl.column}>
            <Text className={cl.title} variant="s" color="gray-8" bold>Структура оплаты</Text>
            <Checkbox
               checked={isAllSelected}
               onChange={e => handleSelectAll(e.target.checked)}
           >
               <Text variant="xxs" color="gray-8">Выбрать все</Text>
            </Checkbox>
            <br />

            <div className={cl.block}>
                <div className={cl.column}>
                    {leftColumnItems}
                </div>
                <div className={cl.column}>
                    {rightColumnItems}
                </div>
                <div className={cl.column}>
                    {thirdColumnItems}
                </div>
            </div>
        </div>
    </div>
)}

        </div>
    );
};

export default Display;
