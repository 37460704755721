import React, { FC, HTMLAttributes } from 'react';
import cl from './style.module.css';
import type { SectionsI } from 'entities/policy/types';
import { useGetPolicyReportQuery } from 'entities/policy/model/endpoints';
import Text from 'shared/ui/Text';
import MovableSidebar from 'shared/ui/MovableSidebar';
import Spinner from 'shared/ui/Spinner';
import cn from 'clsx';
import { useParams } from 'react-router-dom';
import { Collapse } from 'antd';

interface TitlesProps extends HTMLAttributes<HTMLDivElement> {
    isOpenTitles: boolean;
    setIsOpenTitles: (value: boolean) => void;
    sections: SectionsI;
    setSections: (value: SectionsI) => void;
}

const Titles: FC<TitlesProps> = ({ isOpenTitles, setIsOpenTitles, sections, setSections }) => {
    const { reportNumber } = useParams();
    const { data: report, isLoading: isLoadingReport } = useGetPolicyReportQuery(reportNumber ?? '');

    const handleClick = (sectionId: number, subSectionId: number) => setSections({
        section: sectionId,
        subSection: subSectionId
    });

    return (
        <MovableSidebar
            className={cn(cl.titles, { [cl.closedTitles]: !isOpenTitles })}
            isOpenContent={isOpenTitles}
            setIsOpenContent={setIsOpenTitles}
            showArrowText="Показать содержание"
            hideArrowText="Скрыть содержание"
        >
            <div className={cl.wrapper}>
                {isLoadingReport && !report && <Spinner size="m" />}
                {!isLoadingReport && !!report && report.sections.map(section =>
                    <Collapse
                        key={section.id}
                        expandIconPosition="end"
                        defaultActiveKey={sections.section ?? report.sections[0].id}
                        ghost
                    >
                        <Collapse.Panel
                            className={cl.section}
                            header={
                                <Text
                                    className={cl.sectionText}
                                    variant="s"
                                    color={sections.section === section.id ? 'brand-2' : 'primary'}
                                    semiBold={sections.section === section.id}
                                >
                                    {section.section_number} {section.section_name}
                                </Text>
                            }
                            key={section.id}
                        >
                            <div>
                                {section.subsections.map(subsection =>
                                    <div
                                        key={subsection.id}
                                        className={cn(cl.subsection, { [cl.active]: sections.section === section.id && sections.subSection === subsection.id })}
                                        role="button"
                                        tabIndex={0}
                                        onClick={() => handleClick(section.id, subsection.id)}
                                        onKeyDown={e => e.key === 'Enter' && handleClick(section.id, subsection.id)}
                                    >
                                        <Text
                                            variant="s"
                                            color={sections.section === section.id && sections.subSection === subsection.id ? 'brand-2' : 'gray-9'}
                                            semiBold={sections.section === section.id && sections.subSection === subsection.id}
                                        >
                                            {subsection.subsection_name}
                                        </Text>
                                    </div>
                                )}
                            </div>
                        </Collapse.Panel>
                    </Collapse>
                )}
            </div>
        </MovableSidebar>
    );
};

export default Titles;
