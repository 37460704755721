import React, { FC, useMemo, useState } from 'react';
import cl from './style.module.css';
import Text from 'shared/ui/Text';
import { ALPHABET } from 'shared/constants';
import { Button, Checkbox, Collapse, Input, Select } from 'antd';
import { setCities } from 'shared/store/reducers/commonSlice';
import { ISubjectOption } from '../../types';
import { useGetFiltersQuery } from '../../model/endpoints';
import { useAppDispatch } from 'shared/hooks/useAppDispatch';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { useParams } from 'react-router-dom';
import { group } from 'console';
import {} from 'antd';


interface CitiesAlphabetProps {
    searchValue: string;
}

const { Option } = Select;

const CitiesAlphabet: FC<CitiesAlphabetProps> = ({ searchValue }) => {
    const dispatch = useAppDispatch();
    const { reportNumber } = useParams();
    const { cities } = useAppSelector(state => state.common);
    const { data: filters } = useGetFiltersQuery(reportNumber ?? '0');


    const [activeKey, setActiveKey] = useState<string | null>(null);

    // Состояние для хранения выбранного города
    const [selectedCity, setSelectedCity] = useState<string | undefined>(undefined);


    const regions = useMemo(() => filters && Object.entries(filters)
        .filter(([key, _]) => key === 'subjects')[0][1] as unknown as ISubjectOption[], [filters]);

    const filteredRegions = useMemo(() => regions?.filter(r => r.cities.some(c =>
        c.name.toString().toLowerCase().startsWith(searchValue.toLowerCase()))), [regions, searchValue]);

    const filteredAlphabet = useMemo(() =>
        ALPHABET.split('').filter(letter => filteredRegions?.some(r => r.cities.some(c =>
            c.name.toString().toLowerCase().startsWith(letter.toLowerCase())))), [filteredRegions, ALPHABET]);

    const isAllSelected = useMemo(() => regions?.every(r =>
        r.cities.every(city => cities?.split(',').some(c => c === city.id.toString()))), [regions, cities]);

    const moscow = useMemo(() => regions?.find(r =>
        r.cities.find(city => city.name.toString().toLowerCase() === 'москва'))?.cities.find(city =>
        city.name.toString().toLowerCase() === 'москва'), [regions]);


    const defaultCity = useMemo(() => {
        // Поиск Москвы в списке
        const moscow = regions?.find(r => r.cities.find(city => city.name.toString().toLowerCase() === 'москва'))?.cities.find(city => city.name.toString().toLowerCase() === 'москва');
        if (moscow) return moscow;

        // Возврат undefined, если города не найдены
        return undefined;
    }, [regions]);


    // Обработчик изменения выбранного города
    const handleCityChange = (value: string) => {
        setSelectedCity(value);
        // Обновите список выбранных городов
        dispatch(setCities(value));
    };

    // Получаем список городов для селектора
    const citiesOptions = useMemo(() => {
        return regions?.flatMap(region => region.cities).map(city => (
            <Option key={city.id} value={city.id.toString()}>{city.name}</Option>
        ));
    }, [regions]);


    return (
        <div className={[cl.wrapper, 'cities-alphabet'].join(' ')}>
            <div>

                {/* <Select
                    showSearch
                    style={{ width: '100%', marginBottom: '10px' }}
                    placeholder="Выберите основного город"
                    optionFilterProp="label"
                    onChange={handleCityChange}
                    filterOption={(input, option) =>
                        option?.label
                            ? typeof option.label === 'string' &&
                            option.label.toLowerCase().includes(input.toLowerCase())
                            : false
                    }
                    value={selectedCity}
                >
                    {regions?.map(r => r.cities.map(city => (
                        <Option key={city.id} value={city.id.toString()} label={city.name}>
                            {city.name}
                        </Option>
                    )))}
                </Select> */}

                 {!!defaultCity &&
                    <Checkbox
                        checked={cities?.split(',').some(city => city === defaultCity.id.toString())}
                        onChange={e => dispatch(setCities(e.target.checked
                            ? cities ? `${cities},${defaultCity.id}` : `${defaultCity.id}`
                            : cities?.split(',')
                            .filter(city => city !== defaultCity.id.toString())
                            .join(',') ?? ''
                        ))}
                    >
                        <Text variant="xxs" color="gray-8">{defaultCity.name}</Text>
                    </Checkbox>
                }

                <div className={cl.allDeleteWrapper}>
                    <Checkbox
                        checked={isAllSelected}
                        onChange={e => dispatch(setCities(e.target.checked
                            ? regions?.map(r => r.cities.map(c => c.id).join(',')).join(',') ?? ''
                            : '',
                        ))}
                    >
                        <Text variant="xxs" color="gray-8">Выбрать все</Text>
                    </Checkbox>
                    <Button size="small" onClick={() => dispatch(setCities(''))}>Очистить</Button>
                </div>
            </div>
            <div className={[cl.block, cl.blockLetter].join(' ')}>
                {/* {filteredAlphabet.map((letter, i) =>
            <div key={letter} className={cl.inlineBlock}>
                <div className={`${cl.customCollapsePanel} ${activeKey === i.toString() ? cl.myElement : ''}`}>
                <Collapse
                    expandIconPosition="start"
                    defaultActiveKey={Array.from({length: filteredAlphabet.length}, (_, i) => i.toString())}
                    ghost
                    onChange={() => setActiveKey(letter)} // Обновляйте состояние при изменении
                >
                    
                        <Collapse.Panel
                                header={
                                    <Text variant="m" color="gray-8">
                                        {letter}
                                    </Text>
                                }
                                key={i}
                            >
                                <div className={cl.line}>
                                    {regions?.map(r => r.cities.map(c => c.name.toString().startsWith(letter) &&
                                        <Checkbox
                                            key={c.id}
                                            checked={cities?.split(',').some(city => city === c.id.toString())}
                                            onChange={e => dispatch(setCities(e.target.checked
                                                ? cities ? `${cities},${c.id}` : `${c.id}`
                                                : cities?.split(',')
                                                .filter(city => city !== c.id.toString())
                                                .join(',') ?? ''
                                            ))}
                                        >
                                            <Text variant="xxs" color="gray-8">{c.name}</Text>
                                        </Checkbox>
                                    ))}
                                </div>
                    </Collapse.Panel>
                </Collapse>
                </div>
                <div className={cl.separator} />
            </div>
        )} */}


                {filteredAlphabet?.map((letter, i) => (
                    <div key={i} className={cl.groupWrapper}>
                        <div className={`${cl.customCollapsePanel} ${activeKey === i.toString() ? cl.myElement : ''}`}>
                            <Collapse
                                className={cl.customCollapse}
                                defaultActiveKey={Array.from({ length: filteredAlphabet.length }, (_, letter) => i.toString())}
                                expandIconPosition="start"
                                ghost
                                onChange={key => setActiveKey(Array.isArray(key) ? key[0] : key)}
                            >
                                <Collapse.Panel
                                    key={i}
                                    header={

                                        // <Checkbox
                                        //     checked={cities?.split(',').includes(i.toString())}
                                        //     // onChange={e => handleSelectGroup(letter, e.target.checked)}
                                        // >
                                        <Text variant="s" className={cl.groupHeader} color="gray-8" bold>
                                            {letter}
                                        </Text>
                                        // </Checkbox>
                                    }
                                >

                                    <div className={cl.line}>
                                        {regions?.map(r => r.cities.map(c => c.name.toString().startsWith(letter) &&
                                            <Checkbox
                                                key={c.id}
                                                checked={cities?.split(',').some(city => city === c.id.toString())}
                                                onClick={() => setActiveKey(i.toString())}
                                                onChange={e => dispatch(setCities(e.target.checked
                                                    ? cities ? `${cities},${c.id}` : `${c.id}`
                                                    : cities?.split(',')
                                                    .filter(city => city !== c.id.toString())
                                                    .join(',') ?? '',
                                                ))}
                                            >
                                                <Text variant="xxs" color="gray-8">{c.name}</Text>
                                            </Checkbox>,
                                        ))}
                                    </div>

                                </Collapse.Panel>
                            </Collapse>
                        </div>
                        <div className={cl.separator} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CitiesAlphabet;
