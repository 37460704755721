import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import { ConfigProvider } from 'antd';
import ruRU from "antd/es/locale/ru_RU";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { store } from 'shared/store';
import { Provider } from "react-redux";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <React.StrictMode>
        <Toaster
            position="top-right"
            reverseOrder={false}
            toastOptions={{
                className: '',
                duration: 5000,
                style: {
                    background: '#363636',
                    color: '#fff',
                },

                success: {
                    duration: 3000
                },
            }}
        />
        <Provider store={store}>
            <ConfigProvider locale={ruRU}>
                <DndProvider backend={"ontouchstart" in window ? TouchBackend : HTML5Backend}>
                    <App />
                </DndProvider>
            </ConfigProvider>
        </Provider>
    </React.StrictMode>,
);
