import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetReportsQuery } from 'entities/report/model/endpoints';
import Spinner from 'shared/ui/Spinner';
import Text from 'shared/ui/Text';
import cl from './style.module.css';
import { Report } from 'entities/report/model/schemas';

const Main: FC = () => {
    const navigate = useNavigate();
    const { data, isLoading, isSuccess, isError } = useGetReportsQuery();
    const [reports, setReports] = React.useState<Report[]>([]);

    useEffect(() => {
        if (isSuccess && data) {
            console.log("Data received:", data);

            const reportList = Array.isArray(data) ? data : data.reports;
            console.log("Report List:", reportList);

            if (reportList && reportList.length > 0) {
                // Сразу перенаправляем на первый отчет
                navigate(`/report/${reportList[0].id}`);
            }
        }
    }, [isSuccess, data, navigate]);

    if (isLoading) {
        return <Spinner size="m" />;
    }

    if (isError) {
        return <Text variant="s" color="primary">Произошла ошибка при загрузке отчетов</Text>;
    }

    return null; // Не нужно ничего рендерить, так как мы перенаправляем сразу
};

export default Main;

