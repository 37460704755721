import React, { useRef, useEffect, FC } from 'react';
import { init, getInstanceByDom, registerMap, ECharts, EChartOption, EChartsOptionConfig } from 'echarts';

import type { CSSProperties } from 'react';
import useResize from 'shared/hooks/useResize';

interface ReactEChartsProps {
    option: EChartOption;
    style?: CSSProperties;
    settings?: EChartsOptionConfig;
    geo?: {
        mapName: string;
        geoJson: object;
        specialAreas?: object;
    };
    loading?: boolean;
    height?: string | number;
    resizeRef?: React.RefObject<HTMLElement>;
}

const Chart: FC<ReactEChartsProps> = ({ option, style, settings, loading, height, geo, resizeRef }) => {
    const chartRef = useRef<HTMLDivElement>(null);

    useResize(resizeRef, () => {
        const chart = chartRef.current && getInstanceByDom(chartRef.current);
        if(chart) {
            chart.resize();
        }
    }, true);

    useEffect(() => {
        // Initialize chart
        let chart: ECharts | undefined;
        if (chartRef.current !== null) {
            chart = init(chartRef.current);
        }

        // Add chart resize listener
        // ResizeObserver is leading to a bit janky UX
        function resizeChart() {
            chart?.resize();
        }

        window.addEventListener('resize', resizeChart);

        // Return cleanup function
        return () => {
            chart?.dispose();
            window.removeEventListener('resize', resizeChart);
        };
    }, []);

    useEffect(() => {
        if (geo) {
            registerMap(geo.mapName, geo.geoJson, geo.specialAreas);
        }
    }, [geo]);

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            chart.setOption(option, settings);
        }
    }, [option, settings]); // Whenever theme changes we need to add option and setting due to it being deleted in cleanup function

    useEffect(() => {
        // Update chart
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            if (loading) {
                chart.showLoading();
            } else {
                chart.hideLoading();
            }
        }
    }, [loading]);

    return <div ref={chartRef} style={{ width: '100%', height: height ?? '500px', ...style }} />;
};

export default Chart;
