import { apiSlice } from "shared/store/api";
import { ReportsResponse } from "./schemas";
import { IReportResponse } from '../types';



export const reportApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getReports: builder.query<ReportsResponse, void>({
            query: () => '/report/',
            providesTags: ['Reports'],
            extraOptions: {
                error: 'Не удалось загрузить список отчетов!',
            }
        }),

        getSubReport: builder.query<IReportResponse, {
            reportNumber: string,
            filtersChain?: string,
            periods?: string,
            cities?: string,
            func_groups?: string,
            levels?: string,
            comp_groups?: string,
        }>({
            query: args => {
                const { periods, cities, func_groups, levels, comp_groups } = args;
                let chain = args.filtersChain;

                if (chain) {
                    const arrChain = chain.split(',');
                    const idx = arrChain.findIndex(c => c === 'func_group');
                    if (idx !== -1) {
                        arrChain.splice(idx, 0, 'func_group_root');
                    }
                    arrChain.push('entity_type');
                    chain = arrChain.join(',');
                }

                let url = `/report/${args.reportNumber}/subreport/?chain=${chain}`;

                return ({
                    url,
                    params: {
                        periods: periods || undefined,
                        cities: cities || undefined,
                        func_groups: func_groups || undefined,
                        levels: levels || undefined,
                        comp_groups: comp_groups || undefined,
                    }
                });
            },
            providesTags: ['SubReport'],
            extraOptions: {
                error: 'Не удалось загрузить подотчет!',
            }
        })
    })
});

export const {
    useGetReportsQuery, useLazyGetSubReportQuery
} = reportApiSlice;
