import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICommonSlice } from './types';

const initialState: ICommonSlice = {
    isAuth: localStorage.getItem('isAuth') === 'true',
    filtersChain: '',
    triggerForReportRequest: 0,
    periods: undefined,
    cities: undefined,
    comp_groups: undefined,
    levels: undefined,
    func_groups: undefined,
    qual: undefined,
    isApplyDistrictCoefficient: true,
    currency: '',
    includeTax: true,
    index: undefined,
    isShowAverage: true,
    isShowMedian: false,
    isShowCompanyTable: true,
    isShowEmployeeTable: true,
    columnHeadersInTable: [],
    percentsInTable: [],
    displayObject: [],
    isShowFuncGroupDescription: true,
    isShowLevelDescription: true,
    isShowCompanyCount: true,
    excelDownload: false,
    surveyPeriod: 'Июль 2021',
    countMonths: 0,
};

export const commonSlice = createSlice({
    name: 'commonSlice',
    initialState,
    reducers: {
        setIsAuth: (state, payload: PayloadAction<boolean>) => {
            if(payload.payload) {
                localStorage.setItem('isAuth', 'true');
            } else {
                localStorage.removeItem('isAuth');
            }
            state.isAuth = payload.payload;
        },
        setFiltersChain: (state, payload: PayloadAction<string>) => {
            state.filtersChain = payload.payload;
        },
        setTriggerForReportRequest: (state) => {
            state.triggerForReportRequest =
                state.triggerForReportRequest < 10000 ? ++state.triggerForReportRequest : 0;
        },
        setPeriods: (state,  payload: PayloadAction<string>) => {
            state.periods = payload.payload;
        },
        setCities: (state,  payload: PayloadAction<string>) => {
            state.cities = payload.payload;
        },
        setCompGroups: (state,  payload: PayloadAction<string>) => {
            state.comp_groups = payload.payload;
        },
        setLevels: (state,  payload: PayloadAction<string>) => {
            state.levels = payload.payload;
        },
        setQualifications: (state, action: PayloadAction<string>) => {
            state.qual = action.payload;
        },
        setFuncGroups: (state,  payload: PayloadAction<string>) => {
            state.func_groups = payload.payload;
        },
        setIsApplyDistrictCoefficient: (state,  payload: PayloadAction<boolean>) => {
            state.isApplyDistrictCoefficient = payload.payload;
        },
        setCurrency: (state,  payload: PayloadAction<string>) => {
            state.currency = payload.payload;
        },
        setIncludeTax: (state,  payload: PayloadAction<boolean>) => {
            state.includeTax = payload.payload;
        },
        setIndex: (state,  payload: PayloadAction<'year' | 'abs' | undefined>) => {
            state.index = payload.payload;
        },
        setIsShowAverage: (state,  payload: PayloadAction<boolean>) => {
            state.isShowAverage = payload.payload;
        },
        setIsShowMedian: (state,  payload: PayloadAction<boolean>) => {
            state.isShowMedian = payload.payload;
        },
        setIsShowCompanyTable: (state,  payload: PayloadAction<boolean>) => {
            state.isShowCompanyTable = payload.payload;
        },
        setIsShowEmployeeTable: (state,  payload: PayloadAction<boolean>) => {
            state.isShowEmployeeTable = payload.payload;
        },
        setColumnHeadersInTable: (state,  payload: PayloadAction<string[]>) => {
            state.columnHeadersInTable = [...payload.payload];
        },
        setPercentsInTable: (state,  payload: PayloadAction<string[]>) => {
            state.percentsInTable = payload.payload.filter(p =>
                p.toLowerCase() !== 'медиана');
        },
        setIsShowFuncGroupDescription: (state, action: PayloadAction<boolean>) => {
            state.isShowFuncGroupDescription = action.payload;
        },
        setIsShowLevelDescription: (state, action: PayloadAction<boolean>) => {
            state.isShowLevelDescription = action.payload;
        },
        setIsShowCompanyCount: (state, action: PayloadAction<boolean>) => {
            state.isShowCompanyCount = action.payload;
        },
        setExcelDownload: (state, payload: PayloadAction<boolean>) => {
            state.excelDownload = payload.payload;
        },
        setDisplayObject: (state, payload: PayloadAction<{ percents: string[], columnHeaders: string[] }>) => {
            if (state.displayObject.length === 0) {
                // Устанавливаем начальное значение для "Среднее арифметическое"
                state.displayObject.push({ key: 'Среднее', value: true });
        
                // Добавляем остальные элементы
                payload.payload.percents.forEach(p => {
                    if (p !== 'Среднее') {
                        state.displayObject.push({ key: p, value: true });
                    }
                });
                payload.payload.columnHeaders.forEach(ch => {
                    state.displayObject.push({ key: ch, value: true });
                });
            }
        },
        setAllDisplayObjectValues: (state, action: PayloadAction<{ key: string, value: boolean }[]>) => {
            state.displayObject = action.payload;
        },
        setDisplayObjectField: (state, action: PayloadAction<string>) => {
            const index = state.displayObject.findIndex(item => item.key === action.payload);
            if (index !== -1) {
                state.displayObject[index].value = !state.displayObject[index].value;
            }
        },
        clearDisplayObject: (state) => {
            state.displayObject = [];
        },
        setCountMonths: (state, action) => {
            state.countMonths = action.payload;
          },
          setSurveyPeriod: (state, action) => {
            state.surveyPeriod = action.payload;
          },

    }
})

export const {
    setIsAuth, setFiltersChain, setTriggerForReportRequest, setPeriods, setCities,
    setFuncGroups, setCompGroups, setLevels, setIsApplyDistrictCoefficient,
    setCurrency, setIncludeTax, setIndex, setIsShowAverage, setIsShowMedian,
    setColumnHeadersInTable, setPercentsInTable, setDisplayObject, setDisplayObjectField,
    clearDisplayObject, setIsShowCompanyTable, setIsShowEmployeeTable,
    setAllDisplayObjectValues, setIsShowFuncGroupDescription, setIsShowLevelDescription,
    setIsShowCompanyCount, setQualifications, setExcelDownload, setCountMonths, setSurveyPeriod,
} = commonSlice.actions;

export default commonSlice.reducer;
