import { apiSlice } from 'shared/store/api';

const userApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        login: builder.mutation<void, {
            username: string,
            password: string
        }>({
            query: args => {
                const formData = new FormData();
                formData.append('username', args.username);
                formData.append('password', args.password);
                return ({
                    url: '/user/login/',
                    method: 'POST',
                    body: formData,
                });
            },
            extraOptions: {
                error: 'Ошибка при авторизации!'
            }
        }),

        logout: builder.mutation<void, void>({
            query: () => ({
                url: '/user/logout/',
                method: 'POST',
            }),
            extraOptions: {
                error: 'Ошибка при выходе из аккаунта!'
            }
        }),

        getCsrf: builder.query<void, void>({
            query: () => '/get_csrf/',
            extraOptions: {
                error: 'Ошибка при csrf!'
            }
        })
    })
});

export const {
    useLoginMutation, useLogoutMutation
} = userApiSlice;
