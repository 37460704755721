import React, { FC } from 'react';
import cl from './style.module.css';
import ContactsImage from 'shared/assets/images/contacts.png';
import LogoIcon from 'shared/assets/icons/logo.svg';
import Text from 'shared/ui/Text';
import { ReactSVG } from 'react-svg';

const Contacts: FC = () => {
    return (
        <div className={cl.wrapper}>
            <div className={cl.textWrapper}>
                <Text variant="header" color="brand-1">Контакты</Text>
                <Text className={cl.text} variant="l" color="brand-1">
                    <span>По любым вопросам пишите на почту</span>
                    <div>
                        <a className={cl.email} href="mailto:survey@amtconsult.ru"> survey@amtconsult.ru </a>
                        или звоните по телефонам
                    </div>
                    <div className={cl.tels}>
                        <a className={cl.tel} href="tel:+74957885502">+7 (495) 788-5502, </a>
                        <a className={cl.tel} href="tel:+74955850906">+7 (495) 585-0906</a>
                    </div>
                </Text>
                <ReactSVG src={LogoIcon} />
            </div>
            <img className={cl.image} src={ContactsImage} alt="Картинка" />
        </div>
    );
};

export default Contacts;
