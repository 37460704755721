import { apiSlice } from 'shared/store/api';
import {
    PolicyReportResponse,
    policyReportSchema,
    PolicyReportsResponse,
    policyReportsSchema,
    SectionsResponse,
} from './schemas';

export const policyApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPolicyReports: builder.query<PolicyReportsResponse, void>({
            query: () => '/poll_report/reports/',
            providesTags: ['PolicyReports'],
            extraOptions: {
                schema: policyReportsSchema,
                error: 'Не удалось загрузить список отчетов!',
            }
        }),

        getPolicyReport: builder.query<PolicyReportResponse, string>({
            query: reportId => `/poll_report/reports/${reportId}/`,
            providesTags: ['PolicyReport'],
            extraOptions: {
                schema: policyReportSchema,
                error: 'Не удалось загрузить отчет!',
            }
        }),

        getPolicyReportData: builder.query<SectionsResponse, string>({
            query: reportId => `/poll_report/reports/${reportId}/sections/`,
            providesTags: ['PolicyReportData'],
            extraOptions: {
                error: 'Не удалось загрузить данные отчета!',
            }
        }),
    })
});

export const { useGetPolicyReportsQuery, useGetPolicyReportQuery, useGetPolicyReportDataQuery } = policyApiSlice;
