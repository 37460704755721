import React, { FC, useState, useEffect } from 'react';
import cl from './style.module.css'; // Убедитесь, что у вас есть соответствующие стили
import Text from 'shared/ui/Text';

interface Article {
    id: number;
    title: string;
    content: string;
}

interface Heading {
    id: string;
    text: string;
}

interface FigureData {
  id: string;
  src: string;
  alt: string;
  caption: string;
}

interface SidebarProps {
    articles: Article[];
    onSelectArticle: (articleId: number) => void;
    selectedArticleId: number | null;
}

interface OEmbedData {
  id: string;
  url: string;
}

const Sidebar: FC<SidebarProps> = ({ articles, onSelectArticle, selectedArticleId }) => {
    // Функция для извлечения подзаголовков из HTML контента
    
    const extractHeadings = (html: string): Heading[] => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const headings = doc.querySelectorAll('h3, h4, h5, h6');
        return Array.from(headings).map((heading, index) => ({
          id: `heading-${index}`, // Уникальный ID на основе индекса
          text: heading.textContent || '', // Текст заголовка
        }));
      };
     
    const handleClickOnSubheading = (id: any) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };
    

      
const extractOEmbeds = (html: string): OEmbedData[] => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const oembeds = doc.querySelectorAll('oembed');
  return Array.from(oembeds).map((oembed, index) => {
    // Атрибут url должен быть получен из oembed элемента
    return {
      id: `oembed-${index}`, // Уникальный ID на основе индекса
      url: oembed.getAttribute('url') || '' // URL из атрибута url
    };
  });
};

    return (
        <div className={cl.sidebar}>
            {articles.map(article => (
                <div key={article.id}>
                    <div 
                        className={`${cl.sidebarItem} ${selectedArticleId === article.id ? cl.active : ''}`} 
                        onClick={() => onSelectArticle(article.id)}
                    >
                        <Text variant='s' color="gray-10">{article.title}</Text>
                    </div>
                    {selectedArticleId === article.id && (
                      <div className={cl.subLinks}>
                        {extractHeadings(article.content).map((heading, index) => (
                          <div key={index} className={cl.sidebarSubItem} onClick={() => handleClickOnSubheading(heading.id)}>
                            <Text variant='s' color="gray-7">{heading.text}</Text>
                         
                          </div>
                        ))}
                        
                        
                      </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default Sidebar;
