import { array, dictionary, Infer, literals, number, object, string } from 'myzod';
import { SectionTypeEnum } from '../constants';

const policyReportsData = object({
    id: number(),
    report_name: string()
});

const element = object({
    id: number(),
    text: object({
        id: number(),
        text: string().nullable(),
        reportelement: number(),
    }).nullable(),
    diagram: object({
        id: number(),
        values: array(object({
            id: number(),
            description: string().nullable(),
            value: string(),
            diagram: number(),
        })),
        title: string().nullable(),
        reportelement: number(),
    }).nullable(),
    table: object({
        id: number(),
        values: array(object({
            id: number(),
            subTitle: string().nullable(),
            data: object({
                rows: array(dictionary(array(number()))),
                columns: array(string()),
            })
        })),
        title: string().nullable(),
        reportelement: number(),
    }).nullable(),
    element_id: number().or(string()),
    element_code: literals(...Object.values(SectionTypeEnum)),
    subsection: number(),
})

const section = object({
    id: number(),
    section_number: string().nullable(),
    section_name: string().nullable(),
    subsections: array(object({
        id: number(),
        subsection_name: string().nullable(),
        elements: array(element).nullable().optional()
    }))
})

export const policyReportSchema = object({
    id: number(),
    report_name: string(),
    sections: array(section)
});

export const sectionsSchema = array(section);

export const policyReportsSchema = array(policyReportsData);

export type PolicyReportsResponse = Infer<typeof policyReportsSchema>;

export type PolicyReportResponse = Infer<typeof policyReportSchema>;

export type SectionsResponse = Infer<typeof sectionsSchema>;

export type ElementI = Infer<typeof element>;
