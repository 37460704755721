import React, { forwardRef } from 'react';

import cl from './style.module.css';

export interface TextProps
    extends Omit<React.HTMLAttributes<HTMLElement>, 'color'> {
    variant:
        | 'header'
        | 'header-small'
        | 'subheader'
        | 'body'
        | 'l'
        | 'm'
        | 'small'
        | 's'
        | 'xs'
        | 'xxs';
    color?: 'primary' | 'blue' | 'red' | 'white' | 'gray-medium' | 'gray-light' | 'brand-1' | 'brand-2' |
        'gray-3' | 'gray-5' | 'gray-7' | 'gray-8' | 'gray-9' | 'gray-10';
    as?: string | React.ComponentType<any>;
    medium?: boolean;
    semiBold?: boolean;
    bold?: boolean;
    className?: string;
}

const Text = forwardRef<HTMLElement, TextProps>(
    (
        { variant, as = 'span', color = 'primary', className, medium, semiBold, bold, ...props },
        ref,
    ) => {
        const Component = as;

        return (
            <Component
                className={[cl[variant], medium && cl.medium, semiBold && cl.semiBold, bold && cl.bold, className].join(' ')}
                ref={ref}
                style={{
                    color: `var(--color-${color})`,
                }}
                {...props}
            />
        );
    },
);

Text.displayName = 'Text';

export default Text;
