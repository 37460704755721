export enum FieldsNameEnum {
    MONTH_YEAR = 'month_year',
    CITY = 'city',
    FUNC_GROUP_ROOT = 'func_group_root',
    FUNC_GROUP = 'func_group',
    LEVEL = 'level',
    COMP_GROUP = 'comp_group',
    ENTITY_TYPE = 'entity_type'
}

export interface ITreeNode {
    id?: number;
    type?: FieldsNameEnum;
    description?: string;
    salaries?: {
        [key: string | number]: {
            [key: string | number]: number | null;
        }
    };
    values?: {
        [key: string | number] : ITreeNode | (number | string)[][][][]
    };
}

export interface IConst {
    [key: string | number]: {
        [key: string | number]: {
            [key: string | number]: string
        }
    }
}

export interface IReportResponse {
    excel?: boolean;
    tree: {
        [key: string | number]: ITreeNode;
    }
    constants: IConst;
}

export interface IColumnObj {
    [key: string]: (number | null)[]
}
