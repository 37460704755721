import React, { FC, HTMLAttributes } from 'react';
import cl from './style.module.css';
import { Tooltip } from 'antd';
import Text from '../Text';
import cn from 'clsx';

interface MovableSidebarProps extends HTMLAttributes<HTMLDivElement> {
    isOpenContent: boolean;
    setIsOpenContent: (value: boolean) => void;
    showArrowText: string;
    hideArrowText: string;
}

const MovableSidebar: FC<MovableSidebarProps> = ({ className, isOpenContent, setIsOpenContent, showArrowText, hideArrowText, children }) => (
    <div className={cn(cl.wrapper, className, { [cl.wrapperHidden]: !isOpenContent })}>
        {children}
        <Tooltip
            placement="right"
            title={<Text variant="s" color="white">{isOpenContent ? hideArrowText : showArrowText}</Text>}
        >
            <div
                className={cl.arrowWrapper}
                role="button"
                tabIndex={0}
                onClick={() => setIsOpenContent(!isOpenContent)}
                onKeyDown={e => e.key === 'Enter' && setIsOpenContent(!isOpenContent)}
            >
                <div className={cl.arrow} />
            </div>
        </Tooltip>
    </div>
);

export default MovableSidebar;
