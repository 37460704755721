/* global ValueOf */
import React, { HTMLAttributes } from 'react';
import cl from './style.module.css';
import { FC } from "react/index";
import { LoadingOutlined } from "@ant-design/icons";
import cn from 'clsx';

export const SpinnerSizeEnum = {
    XS: 'xs',
    S: 's',
    M: 'm',
    L: 'l',
} as const;

interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
    size?: ValueOf<typeof SpinnerSizeEnum>;
}

const Spinner: FC<SpinnerProps> = ({
    className,
    size = SpinnerSizeEnum.S
}) => {
    return (
        <LoadingOutlined className={cn(cl.spinner, className, {
            [cl.xs]: size === SpinnerSizeEnum.XS,
            [cl.s]: size === SpinnerSizeEnum.S,
            [cl.m]: size === SpinnerSizeEnum.M,
            [cl.l]: size === SpinnerSizeEnum.L,
        })} />
    );
};

export default Spinner;
